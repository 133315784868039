<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { useStreamingStore } from '@/stores/streaming.store'
import type { EmbedIdentifiers } from '@/types/Custom3qTypes'
import type { components as components3q } from '@/types/types3q'

type embedParams = components3q['schemas']['FileEmbedParams']

const props = defineProps<{
  identifiers: EmbedIdentifiers
  embedParams?: embedParams
}>()

const embedCode = ref('')

const streamingStore = useStreamingStore()
const { getLiveEmbedCode, getVodEmbedCode } = streamingStore

const loadLiveEmbedCode = async (channelId: number, params?: embedParams | undefined) => {
  const data = await getLiveEmbedCode(channelId, params)

  if (data.ChannelEmbedCodes.iFrame) {
    embedCode.value = data.ChannelEmbedCodes.iFrame
  } else {
    throw new Error('No iFrame embed code found')
  }
}

const loadVodEmbedCode = async (
  projectId: number,
  fileId: number,
  params?: embedParams | undefined
) => {
  const data = await getVodEmbedCode(projectId, fileId, params)

  if (data.FileEmbedCodes.iFrame) {
    embedCode.value = data.FileEmbedCodes.iFrame
  } else {
    throw new Error('No iFrame embed code found')
  }
}

onMounted(() => {
  if ('channelId' in props.identifiers) {
    loadLiveEmbedCode(props.identifiers.channelId, props.embedParams)
  } else if ('projectId' in props.identifiers && 'fileId' in props.identifiers) {
    loadVodEmbedCode(props.identifiers.projectId, props.identifiers.fileId, props.embedParams)
  }
})
</script>

<template>
  <div v-if="embedCode" v-html="embedCode" />
  <div v-else>Loading player...</div>
</template>
