import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import type {
  Channel3q,
  FileData,
  LiveEmbedResponse3q,
  ProgressResponse,
  Project3q,
  UploadVodFileResponse,
  VodEmbedResponse3q
} from '@/types/Custom3qTypes'
import type { components } from '@/types/swagger'
import type { components as components3q } from '@/types/types3q'
import { convertLabel } from '@/utils/api3q/convertLabel'
import { createQueryString } from '@/utils/api3q/createQueryString'
import { tcFetch } from '@/utils/tcFetch'

/**
 * 3Q Types
 */
type Channel = components3q['schemas']['Channel']
type ChannelEvent = components3q['schemas']['ChannelEvent']
type ChannelSetting = components3q['schemas']['ChannelSetting']
type EmbedParams = components3q['schemas']['FileEmbedParams']
type File3Q = components3q['schemas']['File2']
type ProjectPostParams = components3q['schemas']['ProjectPostParams']
type Stream = components3q['schemas']['Stream']

/**
 * Backend Types
 */
type MonthlyUsageDto = components['schemas']['MonthlyUsageDto']
type UsageTotalsDto = components['schemas']['UsageTotalsDto']

export const useStreamingStore = defineStore('streaming', () => {
  /**
   * ----- Internal Variables -----
   */
  const { t } = useI18n()
  const toast = useToast()

  const url = import.meta.env.VITE_BACKEND_URL
  const url3q = import.meta.env.VITE_BACKEND_URL + '/3qproxy'
  const vodProjectId = import.meta.env.VITE_3Q_VOD_PROJECT_ID

  const xhr = ref<XMLHttpRequest | null>(null)
  const uploadPaused = ref(false)

  /**
   * ----- Exported Refs  -----
   */
  const currentProject = ref<Project3q | null>(null)
  const uploadFileId = ref<number | null>(null)
  const uploadLocation = ref<string | null>(null)
  const uploadPercentage = ref<number>(0)
  const uploadStatus = ref<string>('')

  /**
   * ----- CRUD Actions 3Q API -----
   */

  /**
   * Create a new live project
   * The project name will be converted with {@link convertLabel} which inserts the client name.
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Projects/post_api2_qmsdn_api2_project
   *
   * @param projectName - The name of the project.
   */
  const createLiveProject = async (projectName: string): Promise<Stream> => {
    const convertedProjectName = convertLabel(projectName)
    const payload: ProjectPostParams = {
      Label: convertedProjectName,
      StreamTypeId: 2 // 1 = VOD, 2 = Live
    }

    const response = await tcFetch('POST', `${url3q}/projects`, payload)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedCreateProject')}: ${errorText.message}`)
    }

    const { ProjectId } = await response.json()

    const newProject = await getProject(+ProjectId)
    currentProject.value = newProject

    return newProject
  }

  /**
   * Get a project by its ID
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Projects/get_api2_qmsdn_api2_project_get
   *
   * @param projectId - The ID of the project.
   */
  const getProject = async (projectId: number): Promise<Stream> => {
    const response = await tcFetch('GET', `${url3q}/projects/${projectId}`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetProject')}: ${errorText.message}`)
    }

    const jsonResponse: Stream = await response.json()
    currentProject.value = { ...jsonResponse, channels: [] }

    return jsonResponse
  }

  /**
   * Get all channels for a project
   * Ingest points are nested under project channels.
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Projects/get_api2_qmsdn_api2_project_getchannels
   *
   * @param projectId - The ID of the project.
   */
  const getChannels = async (projectId: number) => {
    const response = await tcFetch('GET', `${url3q}/projects/${projectId}/channels`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetChannels')}: ${errorText.message}`)
    }

    const jsonResponse: { Channels?: Channel[] } = await response.json()

    if (currentProject.value) {
      currentProject.value.channels =
        jsonResponse.Channels?.map((channel) => ({
          ...channel,
          ingest: null // Will be populated later
        })) || []
    } else {
      console.warn('No currentProject provided.')
    }

    return jsonResponse
  }

  /**
   * Get ingest points for a channel
   * Ingest points are nested under project channels.
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Channels/get_api2_qmsdn_api2_channel_getingest
   *
   * @param channelId
   */
  const getIngest = async (channelId: number) => {
    const response = await tcFetch('GET', `${url3q}/channels/${channelId}/ingest`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetIngest')}: ${errorText.message}`)
    }

    const jsonResponse: ChannelSetting = await response.json()

    // Find the corresponding channel and attach its ingest data
    if (currentProject.value && currentProject.value.channels) {
      const channel = currentProject.value.channels.find((c) => c.Id === channelId)
      if (channel) {
        channel.ingest = jsonResponse
      }
    }

    return jsonResponse
  }

  /**
   * Get the embed code for a live channel
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Channels/get_api2_qmsdn_api2_channel_getembed
   *
   * @param channelId - The ID of the channel.
   * @param params - Optional query parameters.
   */
  const getLiveEmbedCode = async (channelId: number, params?: EmbedParams) => {
    let queryString = ''

    if (params) {
      queryString = createQueryString(params)
    }

    const response = await tcFetch('GET', `${url3q}/channels/${channelId}/embed?${queryString}`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEmbedCode')}: ${errorText.message}`)
    }

    return (await response.json()) as LiveEmbedResponse3q
  }

  /**
   * Get the embed code for a VOD file
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Files/get_api2_qmsdn_api2_project_files_file_getembed
   *
   * @param projectId - The ID of the project.
   * @param fileId - The ID of the file.
   * @param params - Optional query parameters.
   */
  const getVodEmbedCode = async (projectId: number, fileId: number, params?: EmbedParams) => {
    let queryString = ''

    if (params) {
      queryString = createQueryString(params)
    }

    const response = await tcFetch(
      'GET',
      `${url3q}/projects/${projectId}/files/${fileId}/playouts/default/embed?${queryString}`
    )

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEmbedCode')}: ${errorText.message}`)
    }

    return (await response.json()) as VodEmbedResponse3q
  }

  /**
   * Get a VOD file by its ID
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Files/get_api2_qmsdn_api2_project_files_file
   *
   * @param projectId - The ID of the project.
   * @param fileId - The ID of the file.
   */
  const getFile = async (projectId: number, fileId: number) => {
    const response = await tcFetch('GET', `${url3q}/projects/${projectId}/files/${fileId}`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetFile')}: ${errorText.message}`)
    }

    return (await response.json()) as File3Q
  }

  /**
   * Get the encoding progress for a VOD file
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Files/get_api2_qmsdn_api2_project_files_file_progress
   *
   * @param projectId
   * @param fileId
   */
  const getEncodingProgress = async (projectId: number, fileId: number) => {
    const response = await tcFetch('GET', `${url3q}/projects/${projectId}/files/${fileId}/progress`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEncodingProgress')}: ${errorText.message}`)
    }

    return (await response.json()) as ProgressResponse
  }

  /**
   * NOT USED RIGHT NOW
   * Get all events for a channel
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Channels/get_api2_qmsdn_api2_channel_getevents
   *
   * @param channelId - The ID of the channel.
   */
  const getEvents = async (channelId: number) => {
    const response = await tcFetch('GET', `${url3q}/channels/${channelId}/events`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEvents')}: ${errorText.message}`)
    }

    const jsonResponse: { ChannelEvents?: ChannelEvent[] } = await response.json()

    if (!currentProject.value) {
      console.warn('No currentProject provided.')
      return
    }

    const channel = currentProject.value.channels?.find((ch: Channel3q) => ch.Id === channelId)

    if (!channel) {
      console.warn(`Channel with ID ${channelId} not found in currentProject.`)
      return
    }

    channel.channelEvents = jsonResponse.ChannelEvents || []

    return jsonResponse
  }

  /**
   * ----- CRUD Actions Nest Backend -----
   */

  /**
   * Get the total usage for a specific event
   *
   * @param eventId - The ID of the event.
   */
  const getEventUsage = async (eventId: number) => {
    const response = await tcFetch('GET', `${url}/events/${eventId}/usage`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEventUsage')}: ${errorText.message}`)
    }

    return (await response.json()) as UsageTotalsDto
  }

  /**
   * Get the monthly usage for a specific event
   *
   * @param eventId - The ID of the event.
   */
  const getEventMonthlyUsage = async (eventId: number) => {
    const response = await tcFetch('GET', `${url}/events/${eventId}/usage/monthly`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEventMonthlyUsage')}: ${errorText.message}`)
    }

    return (await response.json()) as MonthlyUsageDto[]
  }

  /**
   * Get the total usage of all events
   */
  const getUsage = async () => {
    const response = await tcFetch('GET', `${url}/usage`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetUsage')}: ${errorText.message}`)
    }

    return (await response.json()) as UsageTotalsDto
  }

  /**
   * Get the monthly usage of all events
   */
  const getMonthlyUsage = async () => {
    const response = await tcFetch('GET', `${url}/usage/monthly`)

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetMonthlyUsage')}: ${errorText.message}`)
    }

    return (await response.json()) as MonthlyUsageDto[]
  }

  /**
   * ----- File Upload 3Q API  -----
   */

  /**
   * Get the upload location for a VOD file.
   * The upload process is split into two steps:
   * 1. Get the upload location
   * 2. Upload the file to the location
   *
   * @see https://sdn.3qsdn.com/api/doc#/02.00%20Projects/post_api2_qmsdn_api2_project_files
   *
   * @param fileName - The name of the file.
   * @param fileFormat - The format of the file.
   */
  const getUploadLocation = async (fileName: string, fileFormat: string) => {
    const extractedFileFormat = fileFormat.split('/').pop()

    const payload = {
      FileName: fileName,
      FileFormat: extractedFileFormat
    }

    if (!vodProjectId) {
      console.error('VOD Project ID not found.')
      toast.error(
        `${t('views.streaming.failedGetUploadLocation')}: ${t('views.streaming.projectIdNotFound')}`
      )
    }

    const response = await tcFetch('POST', `${url3q}/projects/${vodProjectId}/files`, payload)

    if (!response.ok) {
      const errorText = await response.json()
      toast.error(`${t('views.streaming.failedGetUploadLocation')}: ${errorText.message}`)
    }

    uploadLocation.value = response.headers.get('location')

    const data: UploadVodFileResponse = await response.json()
    uploadFileId.value = data.FileId

    if (!uploadLocation.value) {
      toast.error(
        `${t('views.streaming.failedGetUploadLocation')}: ${t('views.streaming.uploadLocationNotFound')}`
      )
    }
  }

  /**
   * Upload a file as a whole
   *
   * Inspired by: @link https://github.com/3QSDN/fileupload/blob/master/js/js_upload.html
   *
   * @param file - The file to upload.
   */
  const uploadAsWholeFile = (file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      uploadStatus.value = 'uploading'
      uploadPercentage.value = 0

      if (!uploadLocation.value) {
        toast.error(
          `${t('views.streaming.failedUploadFile')}: ${t('views.streaming.uploadLocationNotFound')}`
        )
        return reject(new Error('Upload location not found'))
      }

      xhr.value = new XMLHttpRequest()

      /**
       * Update the upload progress
       */
      xhr.value.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          uploadPercentage.value = Math.ceil((e.loaded * 100) / e.total)
        }
      }

      /**
       * Handle the upload response after the file is uploaded
       */
      xhr.value.onload = () => {
        if (xhr.value?.status === 201) {
          const response = JSON.parse(xhr.value.responseText)
          uploadStatus.value = 'completed'
          toast.success(`${t('views.streaming.uploadFinished')}: ${response.FileId}`)
          resolve()
        } else {
          uploadStatus.value = 'error'
          toast.error(`${t('views.streaming.failedUploadFile')}: ${xhr.value?.status}`)
          reject(new Error(`Upload failed with status ${xhr.value?.status}`))
        }
      }

      xhr.value.open('PUT', uploadLocation.value, true)
      xhr.value.setRequestHeader('Content-Type', file.type)
      xhr.value.send(file)
    })
  }

  /**
   * Upload a file in chunks
   *
   * Inspired by: @link https://github.com/3QSDN/fileupload/blob/master/js/js_upload.html
   *
   * @param file - The file to upload.
   */
  const uploadFileInChunks = (file: File) => {
    return new Promise((resolve, reject) => {
      uploadStatus.value = 'uploading'
      uploadPercentage.value = 0
      uploadNextChunk(file, 0, resolve, reject)
    })
  }

  /**
   * Upload the next chunk of a file
   * Recursive function that uploads the next chunk of a file.
   *
   * Inspired by: @link https://github.com/3QSDN/fileupload/blob/master/js/js_upload.html
   *
   * @param file - The file to upload.
   * @param uploadedBytes - The number of bytes already uploaded.
   * @param resolve - The resolve function of the promise.
   * @param reject - The reject function of the promise.
   */
  const uploadNextChunk = (
    file: File,
    uploadedBytes: number,
    resolve: Function,
    reject: Function
  ) => {
    if (uploadPaused.value) {
      return
    }

    const maxChunkSize = 5 * 1024 * 1024 // 5 MiB
    const remainingSize = file.size - uploadedBytes
    const currentChunkSize = Math.min(maxChunkSize, remainingSize)

    const chunk = file.slice(uploadedBytes, uploadedBytes + currentChunkSize)
    const currentRangeEnd = uploadedBytes + currentChunkSize - 1
    const contentRange = `bytes ${uploadedBytes}-${currentRangeEnd}/${file.size}`

    xhr.value = new XMLHttpRequest()
    xhr.value.open('PUT', uploadLocation.value!, true)

    /**
     * Update the upload progress
     */
    xhr.value.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const totalUploaded = uploadedBytes + e.loaded
        uploadPercentage.value = Math.ceil((totalUploaded * 100) / file.size)
      }
    }

    /**
     * Handle the upload response after the chunk is uploaded
     */
    xhr.value.onload = () => {
      if (xhr.value?.status === 201 || xhr.value?.status === 200) {
        if (uploadedBytes + currentChunkSize < file.size) {
          uploadNextChunk(file, uploadedBytes + currentChunkSize, resolve, reject) // Upload the next chunk
        } else {
          uploadStatus.value = 'completed'
          const response = JSON.parse(xhr.value.responseText)
          toast.success(`${t('views.streaming.uploadFinished')}: ${response.FileId}`)
          resolve()
        }
      } else if (xhr.value?.status === 308) {
        const rangeHeader = xhr.value.getResponseHeader('Range')
        if (rangeHeader) {
          const uploadedBytes = parseInt(rangeHeader.split('-')[1], 10)
          uploadNextChunk(file, uploadedBytes + 1, resolve, reject)
        }
      } else {
        uploadStatus.value = 'error'
        toast.error(`${t('views.streaming.failedUploadFile')}: ${xhr.value?.status}`)
        reject(new Error(`Upload failed with status ${xhr.value?.status}`))
      }
    }

    xhr.value.setRequestHeader('Content-Range', contentRange)
    xhr.value.send(chunk)
  }

  /**
   * Cancel the file upload and all related refs
   */
  const cancelUpload = () => {
    uploadLocation.value = null
    uploadFileId.value = null
    uploadPaused.value = false
    uploadPercentage.value = 0
    uploadStatus.value = ''

    if (xhr.value) {
      xhr.value.abort()
      xhr.value = null
    }
  }

  /**
   * NOT USED RIGHT NOW
   * Pause the file upload
   */
  const pauseUpload = () => {
    uploadPaused.value = true
    uploadStatus.value = 'paused'
    if (xhr.value) {
      xhr.value.abort()
    }
  }

  /**
   * NOT USED RIGHT NOW
   * Resume the file upload
   *
   * @param file - The file to upload.
   */
  const resumeUpload = (file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      uploadStatus.value = 'uploading'

      if (!file || !uploadLocation.value) {
        return reject(new Error('No file or upload location'))
      }

      xhr.value = new XMLHttpRequest()

      xhr.value.onload = function () {
        if (xhr.value?.status === 308) {
          const rangeHeader = xhr.value.getResponseHeader('Range')
          if (rangeHeader) {
            const uploadedBytes = parseInt(rangeHeader.split('-')[1], 10)
            uploadNextChunk(file, uploadedBytes + 1, resolve, reject)
          } else {
            reject(new Error('Range header missing in response'))
          }
        } else if (xhr.value?.status === 200 || xhr.value?.status === 201) {
          uploadStatus.value = 'completed'
          const response = JSON.parse(xhr.value.responseText)
          toast.success(`${t('views.streaming.uploadFinished')}: ${response.FileId}`)
          resolve()
        } else {
          uploadStatus.value = 'error'
          toast.error(`${t('views.streaming.failedUploadFile')}: ${xhr.value?.status}`)
          reject(new Error(`Resume upload failed with status ${xhr.value?.status}`))
        }
      }

      xhr.value.onerror = () => {
        uploadStatus.value = 'error'
        toast.error(`${t('views.streaming.uploadFailedNetwork')}`)
        reject(new Error('Resume upload failed due to network error'))
      }

      xhr.value.open('PUT', uploadLocation.value!, true)
      xhr.value.setRequestHeader('Content-Range', `bytes */${file.size}`)
      xhr.value.send()
    })
  }

  /**
   * ----- Loading Functions  -----
   */

  /**
   * Load a project with all its channels, ingest points, and events
   *
   * @param liveProjectId3q - The ID of the project.
   */
  const loadProject = async (liveProjectId3q: number) => {
    await getProject(liveProjectId3q)
    await getChannels(liveProjectId3q)

    if (currentProject.value?.channels) {
      for (const channel of currentProject.value.channels) {
        if (channel.Id) {
          await getIngest(channel.Id)
          // await getEvents(channel.Id)
        } else {
          throw new Error('Channel ID is missing.')
        }
      }
    }
  }

  /**
   * ----- Utility Functions  -----
   */

  /**
   * Combine data from various 3Q API endpoints into a single object
   *
   * @param projectId - The ID of the project.
   * @param files - An array of file IDs.
   */
  const combineVodData = async (projectId: number, files: number[]) => {
    const fileCollection: FileData[] = []

    for (const fileId of files) {
      const embedCode = (await getVodEmbedCode(projectId, fileId)).FileEmbedCodes.iFrame || ''
      const file3q: File3Q = await getFile(projectId, fileId)
      const progress = await getEncodingProgress(projectId, fileId)

      const fileData: FileData = {
        embedCode,
        file3q,
        progress
      }

      fileCollection.push(fileData)
    }

    return fileCollection
  }

  /**
   * Reset the current project
   */
  const resetCurrentProject = () => {
    currentProject.value = null
  }

  return {
    currentProject,
    uploadFileId,
    uploadLocation,
    uploadPercentage,
    uploadStatus,
    createLiveProject,
    getProject,
    getChannels,
    getIngest,
    getLiveEmbedCode,
    getVodEmbedCode,
    getFile,
    getEncodingProgress,
    getEvents,
    getEventUsage,
    getEventMonthlyUsage,
    getUsage,
    getMonthlyUsage,
    getUploadLocation,
    uploadAsWholeFile,
    uploadFileInChunks,
    cancelUpload,
    pauseUpload,
    resumeUpload,
    loadProject,
    combineVodData,
    resetCurrentProject
  }
})
