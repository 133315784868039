<script setup lang="ts">
import { VButton, VCard, VModal } from '@techcast/histoire'

import { FormKit } from '@formkit/vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import CloudImage from '@/components/utils/CloudImage.vue'
import { useEventSetup } from '@/composables/event/useEventSetup'
import { cloneable } from '@/composables/useClone'
import { asyncDefault, mappedFrontendNoAccessComponents } from '@/layouts/client/NoAccessLayouts'
import router from '@/router'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import { useDesignsStore } from '@/stores/designs.store'
import { previewEvent } from '@/stores/objects/previewEvent'
import { useRegistrationsAndWebformSubmissionsStore } from '@/stores/registrationsAndWebformSubmissions.store'
import type { CreateRegistrationForm } from '@/types/CreateRegistrationForm'
import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { FormElement } from '@/types/FormElement'
import type { RegistrationFormLogin } from '@/types/RegistrationFormLogin'
import type { components } from '@/types/swagger'
import { arraySortedByStringProperty } from '@/utils/arraySortedByStringProperty'
import { camelCase } from '@/utils/camelCase'
import { getFormattedDate } from '@/utils/getFormattedDate'
import { getImageName } from '@/utils/getImageName'
import {
  generateRandomName,
  isBotDetected,
  isSubmissionTooFast,
  monitorFocusOnElement
} from '@/utils/honeypotFunctions'
import { updateColors } from '@/utils/updateClientFrontendColors'

import { defaultRegistrationForm } from './defaultRegistrationForm'

/****************************************
 * TYPES
 *****************************************/
type Webform = components['schemas']['Webform']
type AgendaItem = components['schemas']['AgendaItem']

/****************************************
 * ROUTER
 *****************************************/
const route = useRoute()

/****************************************
 * COMPOSABLES
 *****************************************/
const {
  i18n,
  t,
  locale,
  switchLanguage,
  eventId,
  currentEvent,
  layoutComponent,
  currentEventLanguage,
  eventStore
} = useEventSetup()

/****************************************
 * STORES
 *****************************************/
const designStore = useDesignsStore()
const { currentDesign } = storeToRefs(designStore)
const { fetchDesignById, resetCurrentDesign } = designStore

const designTemplateStore = useDesignTemplatesStore()
const { currentDesignTemplate } = storeToRefs(designTemplateStore)

const registrationStore = useRegistrationsAndWebformSubmissionsStore()
const {
  fetchAllRegistrations,
  createRegistration,
  createWebformSubmission,
  addWebformToRegistration
} = registrationStore

/****************************************
 * PROPS
 *****************************************/
const props = withDefaults(
  defineProps<{
    isPreview: boolean
    view?: string
    entityType?: string
  }>(),
  {
    isPreview: false
  }
)

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
const eventName = computed(() => {
  const name = route.params.eventName || null
  return typeof name === 'string' ? name.replace(/-$/, '') : null // Remove last '-' character if it exists
})
const isPreviewDesign = props.entityType === 'design'
const isPreviewDesignTemplate = props.entityType === 'designTemplate'
const source = computed(() =>
  isPreviewDesignTemplate
    ? {
        ...previewEvent,
        design: { ...currentDesignTemplate.value, logos: currentDesignTemplate.value.logos }
      }
    : { ...currentEvent.value, design: currentDesign.value }
)
const translatedEventWebformElements = computed(() => {
  if (!currentEvent.value.webform || !currentEvent.value.webform.elements) {
    return [] // Return an empty array or appropriate default value if webform or elements are undefined
  }
  const translateLocale = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (value.hasOwnProperty(currentEventLanguage.value)) {
        return value[currentEventLanguage.value as keyof typeof value]
      } else {
        return Object.keys(value).reduce((accumulator: Record<string, any>, currentKey) => {
          (accumulator as Record<string, any>)[currentKey] = translateLocale(value[currentKey])
          return accumulator
        }, {})
      }
    } else {
      return value
    }
  }
  const translated = currentEvent.value.webform?.elements.map((element) => {
    // remove the config classes from each element, so that they get the classes of the formkit theme
    const { config, ...rest } = element
    // get the desired translation from each element
    return Object.keys(rest).reduce((accumulator: Record<string, any>, currentKey) => {
      accumulator[currentKey] = translateLocale(rest[currentKey])
      return accumulator
    }, {} as Record<string, any>)
  })

  // Extract the submit element from the translated elements
  frontendSubmitElement.value = translated?.find((item) => item.$formkit === 'submit')

  // Change the name of the element to its label so that it gets displayed correctly in the webformSubmission
  return (
    translated &&
    translated
      .map((item) => {
        item.name = camelCase(item.label)

        return item
      })
      .filter((item) => item.$formkit !== 'submit')
  ) // Removing the element with "$formkit": "submit"
})

const translatedDefaultFormElements = computed(() => {
  const translateLocale = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (value.hasOwnProperty(currentEventLanguage.value)) {
        return value[currentEventLanguage.value as keyof typeof value]
      } else {
        return Object.keys(value).reduce((accumulator, currentKey) => {
          (accumulator as Record<string, any>)[currentKey] = translateLocale(value[currentKey])
          return accumulator
        }, {} as Record<string, any>)
      }
    } else {
      return value
    }
  }
  const translated = defaultRegistrationForm.map((element: Record<string, any>) => {
    // get the desired translation from each element
    return Object.keys(element).reduce((accumulator, currentKey) => {
      (accumulator as Record<string, any>)[currentKey] = translateLocale(element[currentKey])
      return accumulator
    }, {} as Record<string, any>)
  })

  return translated
})

/****************************************
 * REFS
 *****************************************/
const registrationId = ref<number>()
const registrationToken = ref('')
const showEventWebform = ref(false)
const frontendSubmitElement = ref() // The submit button of the webform
const refreshFormkitSchema = ref<number>(0)
const isAlreadyRegisteredModalOpen = ref(false)
const emailAlreadyExists = ref(false)
const alreadyRegisteredEmail = ref<string>('')

/****************************************
 * LIFECYCLE HOOKS
 *****************************************/
onMounted(async () => {
  if (eventId.value && !isPreviewDesignTemplate) {
    // Update the currentEvent reference with the fetched event
    await eventStore.fetchEventById(+eventId.value, true)

    if (currentEvent.value.design?.id) {
      await fetchDesignById(+currentEvent.value.design.id)
    }
  }

  updateColors(source.value.design.colors as any as DesignColorVariables)

  // Look for the current locale of this event: if the event has a URL with the same name as the event, it means that the event is available in this language
  if (currentEvent.value && currentEvent.value.url) {
    for (const key of Object.keys(currentEvent.value.url) as Array<keyof typeof currentEvent.value.url>) {
      const eventUrlValue = currentEvent.value.url[key]
      const eventNameValue =
        typeof eventName.value === 'string' ? eventName.value.replace(/-$/, '') : eventName.value
      if (cloneable.isEqual(eventUrlValue, eventNameValue)) {
        // console.log('Event language found: ', key)
    
        currentEventLanguage.value = key
      }
    }
  }

  // Monitor focus on honeypot fields
  monitorFocusOnElement(honeypotFieldId, detectBotActivity)
  monitorFocusOnElement(extraFieldId, detectBotActivity)
})

onUnmounted(() => {
  resetCurrentDesign()
})

/****************************************
 * HONEYPOT FIELDS
 *****************************************/
const randomizedHoneypotName = generateRandomName()
const randomizedExtraFieldName = generateRandomName()
const honeypotFieldId = `support_${randomizedHoneypotName}`
const extraFieldId = `support_extra_${randomizedExtraFieldName}`
const formLoadTime = ref<number>(Date.now())
// Flags to handle bot detection
const botDetected = ref(false)

// Function to handle detected bot activity
const detectBotActivity = () => {
  botDetected.value = true
  // console.log('Potential bot activity detected!')

  // Prevent form submission if bot detected
  // This can be done by setting a flag or updating form handling logic
  // Example:
  // formSubmissionAllowed.value = false

  // Log the incident to the server
  // logBotDetection()

  // Notify administrators
  // You can implement notification logic here

  // Optionally, display an error message or redirect
  // Example:
  // alert('Suspicious activity detected. Please contact support.')
  // or
  // router.push('/error-page')
}

// TODO: Function to log bot detection (implement your own logging logic)
// const logBotDetection = async () => {
//   try {
//     await fetch('/api/log-bot-detection', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ timestamp: Date.now(), message: 'Bot activity detected' })
//     })
//   } catch (error) {
//     console.error('Failed to log bot detection:', error)
//   }
// }

/****************************************
 * METHODS
 *****************************************/
 function toggleEmailInputClasses(isError: boolean) {
  const emailInput = document.querySelector('.client-container .formkit-inner input[type="email"]');
  if (emailInput) {
    if (isError) {
      // Remove conflicting classes and apply error state classes
      emailInput.classList.remove('border-none', 'border-0');
      emailInput.classList.add(
        'text-dark-red',
        'dark:text-light-red',
        'border',
        'border-dark-red',
        'dark:border-light-red'
      );
    } else {
      // Remove error state classes and restore original classes
      emailInput.classList.remove(
        'text-dark-red',
        'dark:text-light-red',
        'border',
        'border-dark-red',
        'dark:border-light-red'
      );
      emailInput.classList.add('border-none', 'border-0');
    }
  }
}

/**
 *
 * @param data - The default form data to be submitted
 */
async function submitDefaultForm(data: RegistrationFormLogin) {
  const eventRegistrations = await fetchAllRegistrations(+eventId.value)
  const existingRegistration = eventRegistrations.find(
    (registration) => registration.email === data.email
  )

  if (existingRegistration) {
    // TODO: apply styles to handle the case when the email is already registered
    toggleEmailInputClasses(true)
    emailAlreadyExists.value = true
    alreadyRegisteredEmail.value = data.email
    isAlreadyRegisteredModalOpen.value = true
    return
  }

  const payload = {
    ...data,
    locale: currentEventLanguage.value,
    eventId: +eventId.value
  } as CreateRegistrationForm

  if (
    isBotDetected(data, randomizedHoneypotName, randomizedExtraFieldName) ||
    isSubmissionTooFast(formLoadTime.value)
  ) {
    return
  }

  await createRegistration(+eventId.value, payload).then((response) => {
    if (response.token) {
      registrationToken.value = response.token
      registrationId.value = response.id
      showEventWebform.value = true
    }
  })
}

/**
 *
 * @param data - The user webform data to be submitted
 */
async function submitWebform(data: Webform) {
  if (
    isBotDetected(data, randomizedHoneypotName, randomizedExtraFieldName) ||
    isSubmissionTooFast(formLoadTime.value)
  ) {
    return
  }

  await createWebformSubmission(+eventId.value, data as any).then(async (webformSubmissionResponse) => {
    await addWebformToRegistration(+registrationId.value!, +webformSubmissionResponse.id).then(
      (response) => {
        if (response) {
          router.push({
            name: 'event-show',
            params: { id: +eventId.value, token: registrationToken.value }
          })
        }
      }
    )
  })
}

/**
 * Handles sending the registration email link to the already registered email.
 * @param email - The email address to send the registration link to.
 */
async function handleSendRegistrationEmailLink(email: string) {
  toggleEmailInputClasses(false)
  // TODO: API endpoint to send the registration link
  await sendRegistrationEmailLink(email, eventId.value)

  isAlreadyRegisteredModalOpen.value = false
  alreadyRegisteredEmail.value = ''
}

/**
 * Closes the already registered modal.
 */
function closeAlreadyRegisteredModal() {
  toggleEmailInputClasses(false)
  isAlreadyRegisteredModalOpen.value = false
  alreadyRegisteredEmail.value = ''
}

/****************************************
 * WATCHERS
 *****************************************/
/**
 * This is a workaround to refresh the formkit schema when the locale changes
 * Refresh the formkit schema when the language or the webform elements change
 */
watch([currentEventLanguage, translatedEventWebformElements], () => {
  refreshFormkitSchema.value += 1
})
</script>

<template>
  <div
    v-if="currentEvent"
    :class="[
      'client-container',
      {
        'preview-wrapper': isPreviewDesign || isPreviewDesignTemplate,
        mobile: props.view === 'mobile'
      }
    ]"
  >
    <header class="header-background-color header-text-color relative">
      <nav
        v-if="source.isMultilanguage && source.languages && source.languages.length > 1"
        class="container mx-auto flex max-w-screen-xl justify-end p-3 pb-0 sm:px-8 2xl:px-0"
      >
        <template v-for="language in source.languages" :key="language">
          <a
            v-if="language !== currentEventLanguage"
            target="_blank"
            :href="`/events-public/${source.url[language]}-${source.id}/login`"
          >
            <VButton
              :input-id="language"
              type="button"
              appearance="empty"
              has-language
              :language="language"
              size="small"
            />
          </a>
        </template>
      </nav>
      <div
        class="container relative z-10 mx-auto flex max-w-screen-xl flex-col items-start justify-between gap-6 p-3
          pb-16 sm:flex-row sm:gap-8 sm:p-8 sm:pb-16 2xl:px-0"
      >
        <div
          :class="{
            'sm:order-2': source.design?.positions?.logo === 'right',
            'flex h-32 w-48 items-start justify-start md:h-48': source.design?.logos?.length
          }"
        >
          <CloudImage
            v-if="source.design?.logos?.length"
            :imageName="source.design?.logos[0]?.['public_id']"
            class="max-h-full max-w-full"
            :alt="getImageName(source.design?.logos[0]?.['public_id'])"
          />
        </div>
        <div
          class="flex flex-col items-start"
          :class="source.design?.positions?.logo === 'right' ? '' : 'sm:items-end'"
        >
          <h1
            v-if="source.name"
            class="mb-1 text-left text-4xl font-bold sm:mb-2"
            :class="{
              'sm:self-end sm:text-right': source.design?.positions?.logo !== 'right'
            }"
          >
            {{ source.name[currentEventLanguage] }}
          </h1>
          <p
            v-if="source.subtitle"
            class="my-1 text-left text-xl sm:my-2"
            :class="{
              'sm:self-end sm:text-right': source.design?.positions?.logo !== 'right'
            }"
          >
            {{ source.subtitle[currentEventLanguage] }}
          </p>
          <p
            v-if="source.startDate"
            class="my-1 text-left text-xl sm:my-2"
            :class="{
              'sm:self-end sm:text-right': source.design?.positions?.logo !== 'right'
            }"
          >
            {{ getFormattedDate(i18n.locale.value, source.startDate) }}
          </p>
        </div>
      </div>
    </header>
    <component
      :is="mappedFrontendNoAccessComponents[layoutComponent] || asyncDefault"
      class="page-background-color primary-text-color"
    >
      <template #eventVideo>
        <!-- If there is no streaming, show the previewImage of the event -->
        <CloudImage
          v-if="source.previewImages?.length"
          :imageName="source.previewImages?.[0]?.['public_id']"
          class="!my-0 aspect-video w-full object-cover"
          :alt="getImageName(source.previewImages?.[0]?.['public_id'])"
        />
        <!-- Fallback image -->
        <img
          v-else
          class="aspect-video w-full"
          src="https://images.unsplash.com/photo-1540575467063-178a50c2df87?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          :alt="source.name?.[currentEventLanguage] || 'Event placeholder'"
        />
      </template>

      <template #eventDescription v-if="source.description">
        <p>{{ source.description[currentEventLanguage] }}</p>
      </template>

      <template #eventRegistration v-if="source.protected">
        <div v-if="!showEventWebform" class="w-full p-6 md:p-10">
          <FormKit
            type="form"
            @submit="submitDefaultForm"
            :actions="false"
            :incomplete-message="false"
            novalidate
          >
            <FormKitSchema :schema="translatedDefaultFormElements as FormElement[]" />
            <FormKit
              type="text"
              :name="randomizedHoneypotName"
              :validation="'no-validation'"
              tabindex="-1"
              autocomplete="off"
              outerClass="support-field"
              :id="honeypotFieldId"
            />
            <FormKit
              type="text"
              :name="randomizedExtraFieldName"
              :validation="'no-validation'"
              tabindex="-1"
              autocomplete="off"
              outerClass="support-field"
              :id="extraFieldId"
            />
            <!-- Submit button -->
            <FormKit type="submit">
              <div class="flex items-center">
                <span>{{ t('global.send') }}</span>
              </div>
            </FormKit>
          </FormKit>
        </div>
        <div v-else-if="showEventWebform && source.webform" class="w-full p-6 md:p-10">
          <div class="mb-8" v-html="source.webform.introductionText[currentEventLanguage]"></div>
          <FormKit
            type="form"
            @submit="submitWebform"
            :actions="false"
            :incomplete-message="false"
            novalidate
          >
            <FormKitSchema
              :key="refreshFormkitSchema"
              :schema="translatedEventWebformElements as FormElement[]"
            />
            <FormKit
              type="text"
              :name="randomizedHoneypotName"
              :validation="'no-validation'"
              tabindex="-1"
              autocomplete="off"
              outerClass="support-field"
              :id="honeypotFieldId"
            />
            <FormKit
              type="text"
              :name="randomizedExtraFieldName"
              :validation="'no-validation'"
              tabindex="-1"
              autocomplete="off"
              outerClass="support-field"
              :id="extraFieldId"
            />
            <!-- Submit button -->
            <FormKit type="submit">
              <div class="flex items-center">
                <span>{{ frontendSubmitElement.label }}</span>
              </div>
            </FormKit>
          </FormKit>
        </div>
      </template>

      <template #eventAgenda>
        <template v-if="source.agendaItems && source.agendaItems.length > 0">
          <div class="mb-10 w-full min-w-0 flex-col p-6 md:p-10">
            <h3 class="mb-8 mt-0 font-bold uppercase">{{ $t('global.agenda') }}</h3>
            <div
              v-for="(item, index) in arraySortedByStringProperty(
                source.agendaItems as AgendaItem[],
                'startDate'
              )"
              class="flex-auto"
            >
              <div
                class="mb-6 grid grid-cols-4 gap-6 p-2"
                :class="{
                  'background-pause py-4': item.type === 'pause',
                  'has-separator': item.type !== 'pause'
                }"
              >
                <div
                  class="col-span-4 mb-4 w-full sm:col-span-1 md:col-span-4 lg:col-span-1 xl:col-span-1"
                >
                  <p class="mb-0 mt-0 font-bold">
                    <span>{{ getFormattedDate(currentEventLanguage, item.startDate, true) }}</span>
                    -
                    <span>{{ getFormattedDate(currentEventLanguage, item.endDate, true) }}</span>
                  </p>
                </div>
                <div
                  class="col-span-4 w-full sm:col-span-3 md:col-span-4 lg:col-span-3 xl:col-span-3"
                >
                  <p class="mb-4 mt-0 text-xl font-bold">
                    {{ item.title[currentEventLanguage] }}
                  </p>
                  <template v-if="item.type !== 'pause'">
                    <p
                      v-for="(speaker, i) in item.speakers"
                      :key="`${speaker.firstName} ${speaker.lastName}`"
                      class="mb-4 mt-0 inline-block font-bold uppercase"
                    >
                      <span>{{ speaker.firstName }}&nbsp;{{ speaker.lastName }}</span>
                      <span v-if="i < item.speakers.length - 1" class="text-lg">&nbsp;|&nbsp;</span>
                    </p>
                    <p class="mb-4 mt-0 block">
                      {{ item.description[currentEventLanguage] }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <template #eventSpeakers v-if="source.speakers?.length">
        <h3 class="mb-8 mt-0 font-bold uppercase">{{ $t('global.speakers') }}</h3>
        <div class="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
          <div
            v-for="(speaker, index) in source.speakers"
            :key="`${speaker.firstName} ${speaker.lastName}`"
            class="sm:col-span-1 lg:col-span-1"
          >
            <VCard
              :title="`${speaker.firstName} ${speaker.lastName}`"
              :subtitle="speaker.position"
              :additionalInfo="speaker.company"
              :description="speaker.vita[currentEventLanguage]"
              :image="{
                url:
                  speaker.images?.[0]?.secure_url ||
                  'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D',
                title: `${speaker.firstName} ${speaker.lastName}` || 'Example Image'
              }"
              :trigger-label="t('global.more')"
            />
          </div>
        </div>
      </template>
    </component>

    <footer class="footer-background-color footer-text-color">
      <div
        class="container mx-auto max-w-screen-xl flex-row justify-between p-3 sm:flex sm:p-8 2xl:px-0"
      >
        <div class="flex w-full flex-col gap-8 md:flex-row md:justify-between">
          <div
            v-if="source.design?.footer[0]"
            :class="
              source.design?.footer.length === 1
                ? 'flex w-full justify-center text-left'
                : 'w-full text-left md:w-1/2'
            "
          >
            <slot name="footerLeft">
              <div v-html="source.design?.footer[0].text[currentEventLanguage]"></div>
            </slot>
          </div>
          <div v-if="source.design?.footer[1]" class="w-full text-left md:w-1/2 md:text-right">
            <slot name="footerRight">
              <div v-html="source.design?.footer[1].text[currentEventLanguage]"></div>
            </slot>
          </div>
        </div>
      </div>
    </footer>
    <!-- Email already registered modal -->
    <VModal
      v-model:trigger="isAlreadyRegisteredModalOpen"
      @update:trigger="isAlreadyRegisteredModalOpen = $event"
      avoidCloseModalOnOverlay
    >
      <template #modalHeader>
        <div class="flex flex-col text-center uppercase">
          <p class="text-lg text-dark-grey dark:text-white">
            {{ t('views.events.show.emailAlreadyRegistered') }}
          </p>
        </div>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.show.confirmSendRegistrationLink') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="() => closeAlreadyRegisteredModal()"
          />
          <VButton
            appearance="default"
            :label="t('global.send')"
            size="medium"
            :functionOnClick="
              () => {
                handleSendRegistrationEmailLink(alreadyRegisteredEmail)
              }
            "
          />
        </div>
      </template>
    </VModal>
  </div>
</template>

<style scoped>
/* ----- css style to overwrite formkit form divs ----- */
.client-container {
  form > :deep(div) {
    margin-bottom: 2rem;
  }
  /* class for the honeypot inputs */
  .support-field {
    position: absolute !important;
    left: -9999px !important;
    top: -9999px !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden !important;
    opacity: 0 !important;
    pointer-events: none !important;
    visibility: hidden !important;
  }
}
</style>
