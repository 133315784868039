<script setup lang="ts">
import { VButton, VCheckbox, VInput, VModal } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import draggable from 'vuedraggable'

import { useNavStore } from '../../stores/nav.store'
import type { FormElement } from '../../types/FormElement'
import QuillEditor from '../utils/QuillEditor.vue'

/**
 * @component EditFormElement
 *
 * @description
 * This component is responsible for editing, deleting, and managing form elements within a form builder.
 * It provides a UI for modifying form element properties such as label, placeholder, help text, and options. The component
 * supports translations for multiple languages and handles element-specific properties like validation and required fields.
 * It is only being used in the registration form builder in the components 'events/Registration.vue' and 'registration/New_Edit.vue'.
 *
 * @props
 * - `element` {FormElement} - The form element being edited, containing properties like label, options, and validation rules.
 * - `list` {Array<any>} - The array of form elements. This is updated whenever an element is modified or deleted.
 * - `locale` {string | null} - The current locale used for translations of the form element labels and options.
 *
 * @events
 * - `updateFormElements` - Emitted when the form elements list is updated after saving, deleting, or editing an element.
 *
 * @slots
 * - `modalHeader` - The header slot content for the edit modal.
 * - `modalBody` - The body slot content for the edit modal, where input fields for editing the form element are rendered.
 * - `modalFooter` - The footer slot content for the edit modal, usually containing action buttons.
 *
 * @methods
 * - `saveTemporaryChanges(clickedElement: FormElement)` - Saves the temporary changes made to the form element, updates the form element in the list, and emits the updated list.
 * - `editElement(clickedElement: FormElement)` - Opens the modal to edit the form element and stores the old value of the element.
 * - `deleteElement(clickedElement: FormElement)` - Deletes the form element from the list and emits the updated list.
 * - `addOption(option: string)` - Adds a new option to the options list for select or radio elements.
 * - `removeOption(option: string)` - Removes an option from the options list for select or radio elements.
 *
 * @computed
 * - `translatedElement` - Computes the form element based on the current locale, handling special cases like checkboxes, and applies translations to label, placeholder, help, and options fields.
 *
 * @watchers
 * - `locale` - Watches for changes in the `locale` prop and updates the form elements to reflect the new translations.
 *
 * @usage
 * ```html
 * <EditFormElement
 *   :element="formElement"
 *   :list="formElementsList"
 *   :locale="currentLocale"
 *   @updateFormElements="handleFormElementsUpdate"
 * />
 * ```
 *
 * @dependencies
 * - External Libraries: `vuedraggable`.
 * https://github.com/SortableJS/Vue.Draggable
 */

/****************************************
* TRANSLATIONS
*****************************************/
const { t } = useI18n()

/****************************************
* STORES
*****************************************/
const navStore = useNavStore()
const { isOpen } = storeToRefs(navStore) // Track if the navigation is open in order to change the grid layout

/**
 * ---------- Props ----------
 */
const props = defineProps<{
  element: FormElement
  list: Array<any>
  locale: string
}>()

/****************************************
* REFS
*****************************************/
// Modal control flags for editing and deleting elements
const openEditElementModal = ref<boolean>(false)
const openDeleteElementModal = ref<boolean>(false)
// Input label in multiple languages
const requiredField = ref<boolean | undefined>(props.element.requiredField) // Tracks if the input is going to be required
// Input label in multiple languages
const label = ref<{ de: string; en: string }>(
  (props.element.label as { de: string; en: string }) || {
    de: '',
    en: ''
  }
)
const innerHTML = ref<{ de: string; en: string }>(
  (props.element.__raw__sectionsSchema?.label.attrs.innerHTML as { de: string; en: string }) || {
    de: '',
    en: ''
  }
)
const placeholder = ref<{ de: string; en: string }>(
  (props.element.placeholder as { de: string; en: string }) || {
    de: '',
    en: ''
  }
)
const help = ref<{ de: string; en: string }>(
  (props.element.help as { de: string; en: string }) || {
    de: '',
    en: ''
  }
)
// Special case: options for select or radio inputs in multiple languages
const optionsList = ref<string[] | { de: string[]; en: string[] }>(
  (props.element.options as { de: string[]; en: string[] }) || { de: [], en: [] }
)
const newOption = ref<string>('') // Holds the new option being added by the user in select or radio inputs
const oldElement = ref<FormElement>() // Keeps track of the old value of the element being edited

/****************************************
* COMPUTED VARIABLES
*****************************************/
// Computed property that translates form element fields based on the current locale
const translatedElement = computed(() => {
  // Special case for checkboxes, where the label is stored in the `innerHTML` property
  if (props.element.name === 'checkbox') {
    return {
      ...props.element,
      requiredField: requiredField.value,
      label: 'checkbox',
      // This is a special case for the checkbox element, where the label is stored in the innerHTML property
      // with Formkit you can pass the value raw (https://formkit.com/essentials/schema#raw-values)
      __raw__sectionsSchema: {
        label: {
          $el: 'label',
          attrs: {
            innerHTML: props.locale ? innerHTML.value[props.locale as keyof typeof innerHTML.value] : '' // Translate the label based on the locale
          }
        }
      }
    }
  } else {
    // For other elements, translate label, placeholder, help text, and options
    return {
      ...props.element,
      label: props.locale ? label.value[props.locale as keyof typeof label.value] : '',
      placeholder: props.locale ? placeholder.value[props.locale as keyof typeof placeholder.value] : '',
      help: props.locale ? help.value[props.locale as keyof typeof help.value] : '',
      options: props.locale && typeof optionsList.value === 'object' && !Array.isArray(optionsList.value) ? optionsList.value[props.locale as keyof typeof optionsList.value] : [],
      requiredField: requiredField.value
    }
  }
})


// Emit function to notify the parent component of form element changes
const emit = defineEmits(['updateFormElements'])

/**
 *
 * ---------- Methods ----------
 */
/**
 * Saves the temporary changes made to the form element and updates the form elements list.
 * Also handles the validation rules if the element is required.
 */
function saveTemporaryChanges(clickedElement: FormElement) {
  const index: number = props.list.findIndex((element) => element.id === clickedElement.id)
  const currentElement: FormElement = props.list[index]

  // Create a new updated element based on the current element and required field
  const updatedElement: FormElement = {
    ...currentElement,
    requiredField: requiredField.value ? requiredField.value : undefined
  }

  // Add validation rules for required fields, except for the submit button
  if (requiredField.value && currentElement.$formkit !== 'submit') {
    updatedElement.validation = currentElement.$formkit === 'checkbox' ? 'accepted' : 'required'
    updatedElement.validationMessages = {
      accepted: {
        de: 'Bitte akzeptieren Sie die Bedingungen',
        en: 'Please accept the terms'
      },
      requiredField: {
        de: `Bitte füllen Sie das Feld ${props.locale ? currentElement.label[props.locale] : ''} aus`,
        en: `Please complete the field ${props.locale ? currentElement.label[props.locale] : ''}.`
      }
    }
  } else {
    // Remove validation if the field is not required
    delete updatedElement.validation
    delete updatedElement.validationMessages
  }

  // Update options if the element has any (for select/radio inputs)
  if (currentElement.options) {
    updatedElement.options = Array.isArray(optionsList.value) ? { [props.locale]: optionsList.value } : { ...optionsList.value }
  }

  // Replace the element in the list with the updated one
  props.list.splice(index, 1, updatedElement)

  // Emit the updated form elements list to the parent
  emit('updateFormElements', props.list)

  openEditElementModal.value = false // Close the edit modal
}

/**
 * Opens the edit modal and stores the old value of the element being edited.
 */
function editElement(clickedElement: FormElement) {
  oldElement.value = clickedElement // Save the old element for comparison or undo
  openEditElementModal.value = true // Open the edit modal
}

/**
 * Deletes the form element from the list and emits the updated list to the parent component.
 */
function deleteElement(clickedElement: FormElement) {
  const index: number = props.list.findIndex(
    (element) => String(element.id) === String(clickedElement.id)
  )
  if (index !== -1) {
    props.list.splice(index, 1) // Remove the element from the list
  }

  emit('updateFormElements', props.list) // Notify the parent of the updated list

  openDeleteElementModal.value = false // Close the delete modal
}

/**
 * Adds a new option to the options list for select or radio elements.
 */
function addOption(option: string) {
  if (props.locale) {
    (optionsList.value as { [key: string]: string[] })[props.locale] = [...(optionsList.value as { [key: string]: string[] })[props.locale], option]
  }

  newOption.value = ''
}

/**
 * Removes an option from the options list for select or radio elements.
 */
function removeOption(option: string) {
  const index: number = props.locale
    ? (optionsList.value as { [key: string]: string[] })[props.locale].indexOf(option)
    : -1
  if (index !== -1) {
    if (props.locale) {
      ;(optionsList.value[props.locale] as string[]).splice(index, 1)
    }
  }
  newOption.value = ''
}

/****************************************
* WATCHERS
*****************************************/
// Watcher for changes in the locale prop
// When the locale changes, it updates the form elements to apply the correct translations
watch(
  () => props.locale,
  () => {
    emit('updateFormElements', props.list)
  }
)
</script>

<template>
  <div
    class="rounded border border-misty-grey/20"
    :class="
      element.$formkit !== 'submit'
        ? `relative grid w-full grid-cols-2 grid-rows-2 gap-2 p-4 md:pr-4 ${
            isOpen
              ? 'xl:grid-cols-12 xl:grid-rows-1 xl:gap-4'
              : 'md:grid-cols-12 md:grid-rows-1 md:gap-4'
          }`
        : 'flex w-full justify-center'
    "
  >
    <div
      v-if="element.$formkit !== 'submit'"
      class="handle row-span-1 flex h-full w-full cursor-move items-center justify-start py-4 xl:col-span-1
        xl:justify-center"
    >
      <!-- This icon will be used to drag and drop the form elements with the .handle class -->
      <FontAwesomeIcon
        v-if="element.$formkit !== 'submit'"
        :icon="['fal', 'arrows-up-down-left-right']"
      />
    </div>
    <div
      v-if="element.$formkit !== 'submit'"
      class="hidden h-full w-full items-center justify-start py-4 md:flex"
      :class="isOpen ? 'xl:col-span-3 xl:row-span-1' : 'md:col-span-3 md:row-span-1'"
    >
      <span class="capitalize">{{ element.$formkit }} {{ element.requiredField ? '*' : '' }}</span>
    </div>
    <div
      class="xl:col-start-0 col-span-3 col-start-1 row-start-2 py-4"
      :class="isOpen ? 'xl:col-span-5 xl:row-span-1' : 'md:col-span-5 md:row-span-1'"
    >
      <FormKitSchema :schema="translatedElement" />
    </div>
    <div
      class="col-start-3 row-start-1 flex items-center justify-end gap-4 py-4"
      :class="isOpen ? ' xl:col-span-3 xl:row-span-1' : ' md:col-span-3 md:row-span-1'"
    >
      <VButton
        v-if="element.$formkit !== 'submit'"
        type="button"
        appearance="empty"
        size="medium"
        class="cursor-pointer"
        :functionOnClick="() => editElement(element)"
      >
        <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="size-5 p-1.5" />
      </VButton>
      <VButton
        v-if="element.$formkit !== 'submit'"
        type="button"
        appearance="empty"
        size="medium"
        class="cursor-pointer"
        :functionOnClick="() => deleteElement(element)"
      >
        <FontAwesomeIcon :icon="['fal', 'trash-can']" class="size-5 p-1.5" />
      </VButton>
    </div>
    <!-- MODAL START: Edit form element -->
    <VModal
      v-model:trigger="openEditElementModal"
      :function-on-close="() => saveTemporaryChanges(element)"
    >
      <template #modalHeader>
        <p class="capitalize">
          <strong v-if="element.$formkit === 'checkbox'">{{ element.name }}</strong>
          <strong v-else>{{ element.label[locale] }}</strong>
        </p>
      </template>
      <template #modalBody>
        <VCheckbox
          v-if="element.$formkit !== 'submit'"
          v-model="requiredField"
          label="Required"
          class="mb-4"
          inputId="required"
        />
        <VInput
          v-if="element.$formkit !== 'checkbox'"
          v-model="label[locale]"
          type="text"
          label="Label"
          placeholder="Label"
          class="mb-4"
          inputId="label"
        />
        <VInput
          v-if="
            element.$formkit !== 'radio' &&
            element.$formkit !== 'checkbox' &&
            element.$formkit !== 'submit'
          "
          v-model="placeholder[locale]"
          type="text"
          label="Placeholder"
          placeholder="Placeholder"
          class="mb-4"
          inputId="placeholder"
        />
        <VInput
          v-if="element.$formkit !== 'submit' && element.$formkit !== 'checkbox'"
          v-model="help[locale]"
          type="text"
          label="Help"
          placeholder="Help"
          class="mb-4"
          inputId="help"
        />
        <div
          v-if="element.$formkit === 'select' || element.$formkit === 'radio'"
          class="mb-4 flex flex-col"
          role="group"
          aria-label="Configuration buttons"
        >
          <div class="flex gap-4">
            <VInput
              v-model="newOption"
              type="text"
              label="Add Option"
              placeholder="Add Option"
              class="mb-4"
              inputId="newOption"
            />
            <VButton
              type="button"
              appearance="empty"
              :functionOnClick="() => addOption(newOption)"
              :disabled="newOption === ''"
            >
              <FontAwesomeIcon :icon="['fal', 'circle-plus']" class="mr-2 size-5 p-1.5" />
            </VButton>
          </div>
          <div>
            <draggable
              v-model="optionsList[locale]"
              itemKey="name"
              class="list-group flex flex-col gap-4"
            >
              <template #item="{ element }">
                <div
                  class="flex max-w-fit justify-between gap-2 rounded-full border border-misty-grey bg-transparent p-2
                    text-misty-grey"
                >
                  <span class="cursor-move">
                    {{ element }}
                  </span>
                  <VButton
                    type="button"
                    appearance="empty"
                    class="cursor-pointer"
                    :functionOnClick="() => removeOption(element)"
                  >
                    <FontAwesomeIcon :icon="['fal', 'xmark']" class="size-5" />
                  </VButton>
                </div>
              </template>
            </draggable>
          </div>
        </div>
        <QuillEditor
          v-if="element.$formkit === 'checkbox'"
          inputId="checkboxLabel"
          ref="quillEditorCheckbox"
          label="Label"
          placeholder=""
          v-model:content="innerHTML[locale]"
          contentType="html"
        />
      </template>
      <template #modalFooter>
        <div class="flex justify-end">
          <VButton
            appearance="default"
            :label="t('global.close')"
            :functionOnClick="() => saveTemporaryChanges(element)"
          />
        </div>
      </template>
    </VModal>
    <!-- MODAL END: Edit form element -->
  </div>
</template>
