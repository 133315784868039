<script setup lang="ts">
import { useResizeObserver, useScroll } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { computed, nextTick, onMounted, ref } from 'vue'

import TechcastLogoLight from '@/assets/icons/techcast_logo_light.svg'
import TechcastLogoLightSmall from '@/assets/icons/techcast_logo_light_small.svg'
import UserInfo from '@/components/nav/UserInfo.vue'
import LeftMenuEvent from '@/components/nav/left-menu/LeftMenuEvent.vue'
import LeftMenuMain from '@/components/nav/left-menu/LeftMenuMain.vue'
import LeftMenuTemplates from '@/components/nav/left-menu/LeftMenuTemplates.vue'
import ScrollIndicator from '@/components/nav/utilities/ScrollIndicator.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { useNavStore } from '@/stores/nav.store'
import type { ScrollElement } from '@/types/ScrollElement'

import LeftMenuUser from './LeftMenuUser.vue'

/**
 * Component: LeftMenu
 *
 * Description:
 * This component represents the left sidebar navigation menu of the application.
 * The sidebar includes a top logo section, which dynamically switches between different logo sizes based on the sidebar's open or closed state.
 * It also features a scroll indicator that appears when the menu content is scrollable, and a user information section at the bottom.
 *
 * Reactive State:
 * - `currentRouteName` (String): The name of the current route, used to determine which menu sections are active.
 * - `isOpen` (Boolean): Indicates whether the sidebar is open or closed.
 * - `scrollEl` (Ref<ScrollElement | null>): A reference to the scrollable element used to determine if scrolling is needed.
 * - `isScrollable` (Boolean): A flag indicating if the content of the sidebar is scrollable.
 * - `isScrolling` (Boolean): A reactive state to track if the user is currently scrolling.
 * - `arrivedState` (Object): Provides information about the scroll position, such as whether the user has reached the bottom.
 *
 * Methods:
 * - `toggleNav()`: Toggles the visibility of the sidebar by updating the `isOpen` state.
 *
 */

/****************************************
 * STORES
 *****************************************/
const navStore = useNavStore()
const { currentRouteName, isOpen } = storeToRefs(navStore)
const toggleNav = navStore.toggleNav

/****************************************
 * REFS
 *****************************************/
/**
 * The following makes sure the scroll indicator is only displayed when the container is scrollable
 * and the user is hovering over the container. It disappears while the user is scrolling and reacts
 * to window resize.
 * `scrollEl` is currently only attached to the event section, because the others are not long enough.
 */
const scrollEl = ref<ScrollElement | null>(null)
const { isScrolling, arrivedState } = useScroll(scrollEl)
const isScrollable = ref(false)

/****************************************
 * LYFECYCLE HOOKS
 *****************************************/
onMounted(() => {
  const checkScrollable = () => {
    if (scrollEl.value?.getRootElement()) {
      const el = scrollEl.value.getRootElement()
      isScrollable.value = el?.scrollHeight! > el?.clientHeight!
    }
  }

  checkScrollable()

  useResizeObserver(scrollEl, () => {
    nextTick(checkScrollable)
  })
})

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
/**
 * The following computed properties are used to determine which menu section is currently active.
 * - mainActive: Main navigation section when you arrive in the app
 * - eventActive: Event navigation section when you are in an event
 * - templatesActive: Templates navigation section when you are in the templates section
 * - userActive: User profile and management sections when you are in the user section
 */
const mainActive = computed(() =>
  ['home', 'events', 'templates', 'speakers'].includes(currentRouteName.value as string)
)

const eventActive = computed(() =>
  [
    'event-new',
    'event-update',
    'event-video',
    'event-video-live',
    'event-video-vod',
    'event-design',
    'event-interaction',
    'event-downloads-and-links',
    'event-webform',
    'event-email',
    'event-agenda',
    'event-speakers',
    'event-analytics',
    'event-analytics-registrations'
  ].includes(currentRouteName.value as string)
)

const templatesActive = computed(() =>
  [
    'templates-webform',
    'templates-webform-new',
    'templates-webform-update',
    'templates-design',
    'templates-design-new',
    'templates-design-update',
    'templates-email',
    'templates-email-new',
    'templates-email-update'
  ].includes(currentRouteName.value as string)
)

const userActive = computed(() =>
  ['profile', 'management', 'statistics-usage'].includes(currentRouteName.value as string)
)
</script>

<template>
  <nav
    :class="
      twMerge(
        `group flex h-full w-[347px] shrink-0 flex-col rounded-bl-none rounded-tl-none bg-dark-grey p-2.5
        pb-20 shadow transition-[width] motion-reduce:transition-none md:h-[calc(100svh-2rem)] md:rounded-lg
        md:rounded-bl-lg md:rounded-tl-lg`,
        !isOpen && 'w-24'
      )
    "
  >
    <!-- Top Logo Section -->
    <!-- This ended up being a mix of tailwind and custom css:
         aplying background colors was easier via Tailwind
         the rest is done in the custom class nav-toggle underneath
    -->
    <div class="nav-toggle relative mb-4 lg:before:bg-ice-grey lg:before:dark:bg-medium-grey">
      <div
        class="flex flex-col items-center overflow-hidden py-9 after:absolute after:-left-2.5 after:bottom-0
          after:h-[1px] after:w-[calc(100%+20px)] after:bg-misty-grey after:content-['']"
      >
        <I18nRouterLink to="/" class="overflow-hidden">
          <TechcastLogoLight
            v-if="isOpen"
            class="transition-opacity duration-300"
            :class="!isOpen && 'opacity-0'"
          />
          <TechcastLogoLightSmall v-if="!isOpen" />
        </I18nRouterLink>
        <h1
          class="mx-auto my-2.5 w-fit rounded-lg border border-current px-3.5 py-1.5 text-light-grey
            transition-opacity duration-300"
          :class="!isOpen && 'opacity-0'"
        >
          techcast.event.cloud
        </h1>
        <FontAwesomeIcon
          :icon="['fal', 'circle-arrow-left']"
          @click="toggleNav"
          class="absolute -right-5 top-[calc(50%-0.75rem)] hidden size-6 cursor-pointer text-dark-grey transition-all
            lg:block dark:text-light-grey"
          :class="!isOpen && 'rotate-180'"
        />
      </div>
    </div>
    <!-- Menu Section -->
    <LeftMenuMain v-if="mainActive" class="grow" />
    <!-- ScrollIndicator relies on class 'peer' to work -->
    <!-- TODO: Move scrollEl ref to wrapper (right now we can only have one) -->
    <LeftMenuEvent v-show="eventActive" ref="scrollEl" class="peer grow" />
    <LeftMenuTemplates v-if="templatesActive" v-show="templatesActive" class="grow" />
    <!-- User Menu -->
    <LeftMenuUser v-if="userActive" v-show="userActive" class="grow" />
    <!-- Bouncing Scroll Indicator -->
    <ScrollIndicator
      v-if="isScrollable"
      :bottom="arrivedState.bottom"
      :is-scrolling="isScrolling"
      :className="['absolute right-7 bottom-28 text-light-grey/50', !isOpen && 'right-2']"
    />
    <!-- UserInfo Section -->
    <UserInfo class="absolute bottom-2.5 w-[calc(100%-1.25rem)]" />
  </nav>
</template>

<style scoped>
.nav-toggle:before {
  box-shadow:
    inset 0 0 20px 0 rgba(30, 30, 30, 0.2),
    -5px 0 10px 0 rgba(30, 30, 30, 0.2);
  content: '';
  position: absolute;
  right: -0.625rem;
  top: calc(50% - 1.25rem);
  height: 2.5rem;
  width: 2.5rem;
  opacity: 1;
  clip-path: circle(50% at 100% 50%);
}
</style>
