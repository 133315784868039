<script setup lang="ts">
import { VButton, VModal } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import Player3q from '@/components/video/Player3q.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useStreamingStore } from '@/stores/streaming.store'

const { t } = useI18n()

/**
 * ----- Stores -----
 */
const streamingStore = useStreamingStore()
const { currentProject } = storeToRefs(streamingStore)
const { createLiveProject, loadProject, resetCurrentProject } = streamingStore

const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent, addLiveStreamToEvent } = eventStore

/**
 * ----- Refs -----
 */
const isLoading = ref(false)
const isRemoveProjectIdModalOpen = ref(false)
const playerKey = ref(0)

/**
 * ----- Functions -----
 */
const refreshPlayer = () => {
  playerKey.value += 1
}

const submitLiveProject = async () => {
  isLoading.value = true

  const newProject = await createLiveProject(currentEvent.value.name.de)

  if (newProject.Id) {
    await addLiveStreamToEvent(currentEvent.value.id, newProject.Id)
    await loadProject(newProject.Id)
  }

  isLoading.value = false
}

const connectExistingProject = async (event: Event) => {
  event.preventDefault()
  isLoading.value = true

  const projectId = (event.target as HTMLFormElement).projectId.value

  try {
    await loadProject(+projectId)
    await addLiveStreamToEvent(currentEvent.value.id, +projectId)
  } catch (error) {
    console.error('Failed to connect to the existing project', error)
  } finally {
    isLoading.value = false
  }
}

const removeLiveProject = async () => {
  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await addLiveStreamToEvent(currentEvent.value.id, null)
  resetCurrentProject()
}

/**
 * ----- Lifecycle hooks -----
 */
onMounted(async () => {
  const route = useRoute()
  const eventId = route.params.id as string

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await fetchEventById(+eventId)

  await nextTick()

  if (currentEvent.value.liveProjectId3q) {
    await loadProject(currentEvent.value.liveProjectId3q)
  }
})

onUnmounted(() => {
  resetCurrentEvent()
  resetCurrentProject()
})
</script>

<template>
  <MainLayout>
    <div
      class="flex h-full flex-col gap-3 text-dark-grey xl:max-h-[calc(100vh_-_5.5rem)] dark:text-light-grey"
    >
      <h1 class="mb-10 mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">Live</h1>

      <!-- No Live Project connected -->
      <section v-if="!currentEvent.liveProjectId3q">
        <h2 class="my-6 text-xl italic">{{ t('views.events.video.noLiveProject3q') }}</h2>
        <hr class="my-5 border-[1px]" />
        <div class="w-fit">
          <form @submit.prevent="connectExistingProject">
            <div class="mb-4 flex items-center gap-3">
              <label for="project-id-input" class="mb-2 block text-lg font-bold">
                {{ t('views.events.video.enterProjectId') }}
              </label>
              <input
                id="project-id-input"
                type="text"
                class="w-36 rounded-md border px-3 py-2 shadow-sm"
                :placeholder="t('views.events.video.enterProjectIdPlaceholder')"
                name="projectId"
              />
              <VButton
                type="submit"
                appearance="default"
                :disabled="isLoading"
                :label="t('global.save')"
                size="large"
              />
            </div>
          </form>
          <div class="flex items-center justify-between gap-3">
            <h2 class="my-6 text-lg font-bold">
              {{ t('views.events.video.createLiveProject3qLabel') }}
            </h2>
            <VButton
              type="button"
              appearance="default"
              :disabled="isLoading"
              :label="t('views.events.video.createLiveProject3q')"
              size="large"
              :functionOnClick="submitLiveProject"
            />
          </div>
        </div>
      </section>

      <!-- Live Project connected -->
      <section
        v-if="currentProject && currentProject.channels && currentProject.channels[0]"
        class="flex w-full grow flex-col gap-3 xl:grid xl:grow-0 xl:grid-cols-[1fr_1fr] xl:gap-0"
      >
        <div class="h-fit xl:pr-3">
          <p class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelLiveProject3qId') }}
            </span>
            <span
              class="inline-flex w-[62%] items-center gap-2 rounded-t bg-white px-2 py-1 dark:bg-dark-grey"
            >
              {{ currentProject.Id }}
              <font-awesome-icon
                :icon="['fal', 'circle-xmark']"
                class="size-5 cursor-pointer hover:scale-110"
                @click="() => (isRemoveProjectIdModalOpen = true)"
              />
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelLiveProject3q') }}
            </span>
            <span class="inline-block w-[62%] bg-white px-2 py-1 dark:bg-dark-grey">
              {{ currentProject.Label }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelChannelId') }}
            </span>
            <span class="inline-block w-[62%] bg-white px-2 py-1 dark:bg-dark-grey">
              {{ currentProject.channels[0].Id }}
            </span>
          </p>
          <div class="w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">
              {{ t('views.events.video.labelChannelStatus') }}
            </span>

            <ul
              v-if="currentProject.channels[0].ChannelStatus?.[0]"
              class="inline-block w-[62%] rounded-b bg-white px-2 py-1 text-sm dark:bg-dark-grey"
            >
              <li v-for="(value, key) in currentProject.channels[0].ChannelStatus[0]" :key="key">
                <span class="font-bold">{{ key }}: </span>{{ value }}
              </li>
            </ul>
            <p v-else class="inline-block w-[62%] rounded-b bg-white px-2 py-1 dark:bg-dark-grey">
              {{ t('views.events.video.noChannelStatus') }}
            </p>
          </div>
          <p class="mt-3 w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">rtmp: </span>
            <textarea
              :value="currentProject.channels[0].ingest?.IngestPoints?.[0].ServerURI"
              readonly
              class="w-[62%] resize-none rounded-t px-2 py-1 align-top shadow-[inset_0px_0px_2px_0px_black] outline-none
                dark:bg-dark-grey"
              rows="2"
            />
          </p>
          <p class="inline-block h-fit w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">rtmps: </span>
            <textarea
              :value="currentProject.channels[0].ingest?.IngestPoints?.[0].SecureServerURI"
              readonly
              class="w-[62%] resize-none px-2 py-1 align-top shadow-[inset_0px_0px_2px_0px_black] outline-none
                dark:bg-dark-grey"
              rows="2"
            />
          </p>
          <p class="h-fit w-full">
            <span class="inline-block w-[38%] py-1 pr-1 align-top font-bold">Stream Name: </span>
            <textarea
              :value="currentProject.channels[0].ingest?.IngestPoints?.[0].StreamName"
              readonly
              class="w-[62%] resize-none rounded-b px-2 py-1 align-top shadow-[inset_0px_0px_2px_0px_black] outline-none
                dark:bg-dark-grey"
              rows="1"
            />
          </p>
        </div>
        <!-- Video Player -->
        <Player3q
          v-if="currentProject.channels[0].Id"
          :key="playerKey"
          :identifiers="{ channelId: currentProject.channels[0].Id }"
          :embedParams="{ Autostart: false }"
          class="mb-3 h-full min-h-[300px] overflow-hidden xl:h-fit xl:w-full [&>iframe]:aspect-video
            [&>iframe]:h-full [&>iframe]:max-h-[100%] [&>iframe]:w-auto [&>iframe]:max-w-[100%]
            [&>iframe]:rounded [&>iframe]:outline-none xl:[&>iframe]:h-auto xl:[&>iframe]:w-full"
        />
        <div class="flex w-full xl:col-start-2 xl:justify-end">
          <VButton
            type="button"
            appearance="default"
            size="medium"
            :label="t('views.events.video.refreshPlayer')"
            :functionOnClick="refreshPlayer"
          />
        </div>
      </section>

      <!-- Channel Events are not used right now     -->
      <!--      <section v-if="currentProject?.channels">-->
      <!--        <span class="font-bold">Channel Events: </span>-->
      <!--        {{ currentProject.channels[0].channelEvents }}-->
      <!--      </section>-->
    </div>

    <!-- Remove Project ID Confirm Modal -->
    <template #modal>
      <VModal
        :trigger="isRemoveProjectIdModalOpen"
        @update:trigger="isRemoveProjectIdModalOpen = $event"
      >
        <template #modalHeader>
          <p class="text-center text-xl text-dark-grey dark:text-light-grey">
            {{ t('views.events.video.confirmModalHeader') }}
          </p>
        </template>
        <template #modalBody>
          <p class="my-5 text-dark-grey dark:text-light-grey">
            {{ t('views.events.video.confirmModalText') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isRemoveProjectIdModalOpen = false
                }
              "
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.confirm')"
              size="medium"
              :functionOnClick="
                () => {
                  removeLiveProject()
                  isRemoveProjectIdModalOpen = false
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
