<script setup lang="ts">
import {
  VButton,
  VImageUpload,
  VInput,
  VModal,
  VTable,
  type VTableColumn,
  VToggleTwoOptions
} from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import MainLayout from '@/layouts/MainLayout.vue'
import { useDownloadsLinksStore } from '@/stores/downloadsLinks.store'
import { useEventsStore } from '@/stores/events.store'
import { refreshPage } from '@/utils/refreshPage'

const { t } = useI18n()
const toast = useToast()

const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { fetchEventById } = eventStore

const route = useRoute()
const eventId = computed(() => +route.params.id)

onMounted(async () => {
  if (eventId.value) {
    await fetchEventById(+eventId.value)
  }
})

const downloadsColumns: ComputedRef<VTableColumn[]> = computed(() => [
  {
    title: t('global.name'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['name'],
    keyPositionInSmallDevices: 3
  },
  {
    title: 'Url',
    name: 'assets',
    align: 'left',
    valign: 'middle',
    keys: ['assets'],
    keyPositionInSmallDevices: 4
  },
  {
    title: t('global.type'),
    name: 'type',
    align: 'left',
    valign: 'middle',
    keys: ['assets'],
    keyPositionInSmallDevices: 1
  },
  {
    title: t('global.actions'),
    name: 'actions',
    align: 'center',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 2
  }
])

const linksColumns: ComputedRef<VTableColumn[]> = computed(() => [
  {
    title: t('global.name'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['name'],
    keyPositionInSmallDevices: 3
  },
  {
    title: 'Url',
    name: 'url',
    align: 'left',
    valign: 'middle',
    keys: ['url'],
    keyPositionInSmallDevices: 4
  },
  {
    title: t('global.actions'),
    name: 'actions',
    align: 'center',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 2
  }
])

const downloadsLinksStore = useDownloadsLinksStore()
const { currentDownload, currentDownloadFile, currentLink } = storeToRefs(downloadsLinksStore)
const {
  createDownload,
  createLink,
  fetchDownloadById,
  fetchLinkById,
  updateDownload,
  updateLink,
  deleteDownload,
  deleteLink,
  resetCurrentDownload,
  resetCurrentDownloadFile,
  resetCurrentLink
} = downloadsLinksStore

// Used to block the save/update button while processing
const isProcessing = ref<boolean>(false)

// change the value of currentEventLanguage to the current selected language in the toggle component
function handleEventLanguageChange(value: string) {
  currentEventLanguage.value = value as 'de' | 'en'
}

const languageMissing = (): string | void => {
  let missingLanguages = []

  // Check for 'name' field
  if (!currentLink.value.name.de || currentLink.value.name.de.trim() === '') {
    missingLanguages.push('name (de)')
  }
  if (!currentLink.value.name.en || currentLink.value.name.en.trim() === '') {
    missingLanguages.push('name (en)')
  }

  // Check for 'url' field
  if (!currentLink.value.url.de || currentLink.value.url.de.trim() === '') {
    missingLanguages.push('Url (de)')
  }
  if (!currentLink.value.url.en || currentLink.value.url.en.trim() === '') {
    missingLanguages.push('Url (en)')
  }

  // Construct the message
  if (missingLanguages.length > 0) {
    return missingLanguages.join(', ')
  }
}

/**
 * DOWNLOAD SECTION
 */

const isEditUpdateDownloadModalOpen = ref<boolean>(false)
const isDeleteDownloadModalOpen = ref<boolean>(false)

const setcurrentDownloadFile = (e: any) => {
  const { files } = e.target

  if (files && files[0]) {
    currentDownloadFile.value.src = files[0]
    currentDownloadFile.value.type = files[0].type
    currentDownloadFile.value.title = files[0].name
  }
}

const handlecreateDownload = async () => {
  isProcessing.value = true

  // FileReader allows to read the content of a file asynchronously
  const reader = new FileReader()

  // Read the binary data and store it in a buffer
  reader.readAsArrayBuffer(currentDownloadFile.value.src as Blob)

  // When the file is read it triggers the onloaded event
  reader.onloadend = async () => {
    // Convert the buffer to a blob
    const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)])
    // Create a file from the blob including the name and type
    const file = new File([blob], currentDownloadFile.value.title, {
      type: currentDownloadFile.value.type
    })

    // Send the new download to the backend
    await createDownload(eventId.value, currentDownload.value.name, file)
    // Fetch the event again to get the updated downloads
    await fetchEventById(eventId.value)

    await nextTick(() => {
      resetCurrentDownload()
      resetCurrentDownloadFile()
      isEditUpdateDownloadModalOpen.value = false
      isProcessing.value = false
      // TODO: remove this when more specific backend endpoints are made (monkey patch)
      refreshPage()
    })

    toast.success(t('views.events.downloadsLinks.downloadCreated'))
  }
}

const handleUpdateDownload = async () => {
  isProcessing.value = true

  // Check if there is a new file
  if (currentDownloadFile.value.src) {
    // FileReader allows to read the content of a file asynchronously
    const reader = new FileReader()

    // Read the binary data and store it in a buffer
    reader.readAsArrayBuffer(currentDownloadFile.value.src as Blob)

    // When the file is read it triggers the onloaded event
    reader.onloadend = async () => {
      // Convert the buffer to a blob
      const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)])
      // Create a file from the blob including the name and type
      const file = new File([blob], currentDownloadFile.value.title, {
        type: currentDownloadFile.value.type
      })

      // Send the updated download to the backend
      await updateDownload(currentDownload.value.id, currentDownload.value.name, file)
      // Fetch the event again to get the updated downloads
      await fetchEventById(eventId.value)

      await nextTick(() => {
        resetCurrentDownload()
        resetCurrentDownloadFile()
        isEditUpdateDownloadModalOpen.value = false
        isProcessing.value = false
        // TODO: remove this when more specific backend endpoints are made (monkey patch)
        refreshPage()
      })
    }
  } else {
    // Send the updated download to the backend without a new file
    await updateDownload(currentDownload.value.id, currentDownload.value.name)
    // Fetch the event again to get the updated downloads
    await fetchEventById(eventId.value)

    await nextTick(() => {
      resetCurrentDownload()
      resetCurrentDownloadFile()
      isEditUpdateDownloadModalOpen.value = false
      isProcessing.value = false
      // TODO: remove this when more specific backend endpoints are made (monkey patch)
      refreshPage()
    })

    toast.success(t('views.events.downloadsLinks.downloadUpdated'))
  }
}

const handleDeleteDownload = async (downloadId: number) => {
  await deleteDownload(downloadId)
  // Fetch the event again to get the updated downloads
  await fetchEventById(eventId.value)

  await nextTick(() => {
    resetCurrentDownload()
    resetCurrentDownloadFile()
    isDeleteDownloadModalOpen.value = false
    // TODO: remove this when more specific backend endpoints are made (monkey patch)
    refreshPage()
  })

  toast.success(t('views.events.downloadsLinks.downloadDeleted'))
}

const handleOpenNewEditDownloadModal = async (downloadId?: number) => {
  // UPDATE PATH: Fetch the download by id if there is one
  if (downloadId) {
    currentDownload.value = await fetchDownloadById(downloadId)
  }

  isEditUpdateDownloadModalOpen.value = true
}

const handleOpenDeleteDownloadModal = async (downloadId: number) => {
  currentDownload.value = await fetchDownloadById(downloadId)

  isDeleteDownloadModalOpen.value = true
}

/**
 * LINK SECTION
 */

const isEditUpdateLinkModalOpen = ref<boolean>(false)
const isDeleteLinkModalOpen = ref<boolean>(false)

const handleSaveLink = async () => {
  isProcessing.value = true

  // Send the new link to the backend
  await createLink(eventId.value, currentLink.value)
  // Fetch the event again to get the updated links
  await fetchEventById(eventId.value)

  await nextTick(() => {
    resetCurrentLink()
    isEditUpdateLinkModalOpen.value = false
    isProcessing.value = false
    // TODO: remove this when more specific backend endpoints are made (monkey patch)
    refreshPage()
  })

  toast.success(t('views.events.downloadsLinks.linkCreated'))
}

const handleUpdateLink = async () => {
  isProcessing.value = true

  // Send the updated link to the backend
  await updateLink(currentLink.value.id, currentLink.value)
  // Fetch the event again to get the updated links
  await fetchEventById(eventId.value)

  await nextTick(() => {
    resetCurrentLink()
    isEditUpdateLinkModalOpen.value = false
    isProcessing.value = false
    // TODO: remove this when more specific backend endpoints are made (monkey patch)
    refreshPage()
  })

  toast.success(t('views.events.downloadsLinks.linkUpdated'))
}

const handleDeleteLink = async (linkId: number) => {
  await deleteLink(linkId)
  // Fetch the event again to get the updated links
  await fetchEventById(eventId.value)

  await nextTick(() => {
    resetCurrentLink()
    isDeleteLinkModalOpen.value = false
    // TODO: remove this when more specific backend endpoints are made (monkey patch)
    refreshPage()
  })

  toast.success(t('views.events.downloadsLinks.linkDeleted'))
}

const handleOpenNewEditLinkModal = async (linkId?: number) => {
  // UPDATE PATH: Fetch the link by id if there is one
  if (linkId) {
    currentLink.value = await fetchLinkById(linkId)
  }

  isEditUpdateLinkModalOpen.value = true
}

const handleOpenDeleteLinkModal = async (linkId: number) => {
  currentLink.value = await fetchLinkById(linkId)

  isDeleteLinkModalOpen.value = true
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="text-[32px] font-bold lg:text-[42px] xl:text-[58px]">Downloads & Links</h1>
      </div>
      <!-- DOWNLOADS SECTION -->
      <div class="mb-3 flex flex-wrap items-center gap-4">
        <h2 class="text-2xl font-bold">Downloads</h2>
        <VButton
          type="button"
          appearance="default"
          :label="t('views.events.downloadsLinks.createDownloadLabel')"
          :disabled="false"
          size="medium"
          :functionOnClick="handleOpenNewEditDownloadModal"
        >
          <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
        </VButton>
      </div>
      <!-- DOWNLOADS TABLE -->
      <VTable
        v-if="currentEvent.downloads.length > 0"
        :columns="downloadsColumns"
        :data="currentEvent.downloads"
        :filterButtonsInSmallDevices="['name']"
        class="mb-2"
      >
        <template #assets="{ value }">
          <a :href="value[0].secure_url" target="_blank" class="line-clamp-1 font-semibold">{{
            value[0].secure_url
          }}</a>
        </template>
        <template #type="{ value }">
          <p>{{ value[0].format }}</p>
        </template>
        <template #actions="{ value }">
          <VButton
            type="button"
            appearance="empty"
            :functionOnClick="() => handleOpenNewEditDownloadModal(+value)"
          >
            <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
          </VButton>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            :functionOnClick="() => handleOpenDeleteDownloadModal(+value)"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
      <p v-else>{{ t('views.events.downloadsLinks.noDownloadsAvailable') }}</p>

      <hr class="my-10 border-[1px]" />
      <!-- LINKS SECTION -->
      <div class="mb-3 flex flex-wrap items-center gap-4">
        <h2 class="text-2xl font-bold">Links</h2>
        <VButton
          type="button"
          appearance="default"
          :label="t('views.events.downloadsLinks.createLinkLabel')"
          :disabled="false"
          size="medium"
          :functionOnClick="handleOpenNewEditLinkModal"
        >
          <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
        </VButton>
      </div>
      <!-- LINKS TABLE -->
      <VTable
        v-if="currentEvent.links.length > 0"
        :columns="linksColumns"
        :data="currentEvent.links"
        :filterButtonsInSmallDevices="['name']"
      >
        <template #name="{ value }">
          <p class="text-lg text-dark-grey dark:text-white">{{ JSON.parse(value).de }}</p>
          <p class="text-misty-grey/60 dark:text-light-grey/50">{{ JSON.parse(value).en }}</p>
        </template>
        <template #url="{ value }">
          <a
            :href="JSON.parse(value).de"
            target="_blank"
            class="line-clamp-1 text-lg font-semibold text-dark-grey md:line-clamp-none dark:text-white"
          >
            {{ JSON.parse(value).de }}
          </a>
          <a
            :href="JSON.parse(value).en"
            target="_blank"
            class="text-md line-clamp-1 font-semibold text-misty-grey/60 md:line-clamp-none dark:text-light-grey/50"
          >
            {{ JSON.parse(value).en }}
          </a>
        </template>
        <template #actions="{ value }">
          <VButton
            type="button"
            appearance="empty"
            :functionOnClick="() => handleOpenNewEditLinkModal(value)"
          >
            <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
          </VButton>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            :functionOnClick="() => handleOpenDeleteLinkModal(value)"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
      <p v-else>{{ t('views.events.downloadsLinks.noLinksAvailable') }}</p>
    </section>
    <!--  MODALS  -->
    <template #modal>
      <!-- create/update Download -->
      <VModal
        :trigger="isEditUpdateDownloadModalOpen"
        @update:trigger="isEditUpdateDownloadModalOpen = $event"
        :functionOnClose="
          () => {
            resetCurrentDownloadFile()
            resetCurrentDownload()
          }
        "
      >
        <template #modalHeader>
          <h2 v-if="!currentDownload.id" class="text-2xl font-bold">
            {{ t('views.events.downloadsLinks.newDownload') }}
          </h2>
          <h2 v-if="currentDownload.id" class="text-2xl font-bold">
            {{ t('views.events.downloadsLinks.updateDownload') }}
          </h2>
        </template>
        <template #modalBody>
          <form class="grow tracking-wide">
            <VInput
              v-model="currentDownload.name as string"
              type="text"
              :input-id="`download-modal-name-${currentDownload.id}`"
              label="Name"
              placeholder="Name"
              class="mb-5"
            />
            <template v-if="currentDownload.id">
              <p class="font-bold">Aktuelle Datei</p>
              <a
                :href="currentDownload.assets[0].secure_url"
                target="_blank"
                class="mb-5 inline-block text-xs underline"
                >{{ currentDownload.assets[0].secure_url }}</a
              >
            </template>
            <VImageUpload
              v-model="currentDownloadFile.src"
              :input-id="`download-modal-file-${currentDownload.id}`"
              :outer-label="
                currentDownload.id
                  ? t('views.events.downloadsLinks.newFile')
                  : t('views.events.downloadsLinks.file')
              "
              :inner-label="t('views.events.downloadsLinks.chooseFile')"
              :allowed-file-types="'application/pdf'"
              @change="setcurrentDownloadFile"
              class="[&>label]:h-32"
            />
          </form>
        </template>
        <template #modalFooter>
          <p class="font-bold">{{ t('views.events.downloadsLinks.choosenFile') }}</p>
          <p class="mb-5">{{ currentDownloadFile.title }}</p>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isEditUpdateDownloadModalOpen = false
                  resetCurrentDownloadFile()
                  resetCurrentDownload()
                }
              "
            />
            <VButton
              type="submit"
              appearance="default"
              :label="currentDownload.id ? t('global.update') : t('global.save')"
              size="medium"
              :disabled="isProcessing"
              :functionOnClick="currentDownload.id ? handleUpdateDownload : handlecreateDownload"
            />
          </div>
        </template>
      </VModal>
      <!-- delete Download -->
      <VModal
        :trigger="isDeleteDownloadModalOpen"
        @update:trigger="isDeleteDownloadModalOpen = $event"
      >
        <template #modalHeader>
          <h2 class="text-2xl font-bold">{{ t('views.events.downloadsLinks.deleteDownload') }}</h2>
        </template>
        <template #modalBody>
          <p class="mb-5">{{ t('views.events.downloadsLinks.confirmDeleteDownload') }}</p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="() => (isDeleteDownloadModalOpen = false)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :disabled="isProcessing"
              :functionOnClick="() => handleDeleteDownload(currentDownload.id)"
            />
          </div>
        </template>
      </VModal>
      <!-- create/update Link -->
      <VModal
        :trigger="isEditUpdateLinkModalOpen"
        @update:trigger="isEditUpdateLinkModalOpen = $event"
      >
        <template #modalHeader>
          <h2 v-if="!currentLink.id" class="text-2xl font-bold">
            {{ t('views.events.downloadsLinks.newLink') }}
          </h2>
          <h2 v-if="currentLink.id" class="text-2xl font-bold">
            {{ t('views.events.downloadsLinks.updateLink') }}
          </h2>
        </template>
        <template #modalBody>
          <VToggleTwoOptions
            v-if="currentEvent.isMultilanguage"
            v-model="currentEventLanguage"
            input-id="currentEventLanguage"
            leftOptionValue="de"
            rightOptionValue="en"
            @change="handleEventLanguageChange"
          />
          <div v-if="languageMissing()" class="my-3">
            <p class="mb-1">{{ t('views.events.downloadsLinks.missingFields') }}</p>
            <p class="text-dark-red">
              {{ languageMissing() }}
            </p>
          </div>
          <form v-if="currentEventLanguage" class="grow tracking-wide">
            <VInput
              v-model="currentLink.name[currentEventLanguage] as string"
              type="text"
              :input-id="`link-modal-name-${currentLink.id}`"
              label="Name"
              placeholder="Name"
              class="mb-5"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
            />
            <VInput
              v-model="currentLink.url[currentEventLanguage] as string"
              type="text"
              :input-id="`link-modal-url-${currentLink.id}`"
              label="Url"
              placeholder="Url"
              class="mb-5"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
            />
          </form>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="() => (isEditUpdateLinkModalOpen = false)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="currentLink.id ? t('global.update') : t('global.save')"
              size="medium"
              :disabled="isProcessing || languageMissing() !== undefined"
              :functionOnClick="currentLink.id ? handleUpdateLink : handleSaveLink"
            />
          </div>
        </template>
      </VModal>
      <!-- delete Link -->
      <VModal :trigger="isDeleteLinkModalOpen" @update:trigger="isDeleteLinkModalOpen = $event">
        <template #modalHeader>
          <h2 class="text-2xl font-bold">{{ t('views.events.downloadsLinks.deleteLink') }}</h2>
        </template>
        <template #modalBody>
          <p class="mb-5">{{ t('views.events.downloadsLinks.confirmDeleteLink') }}</p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="() => (isDeleteLinkModalOpen = false)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :disabled="isProcessing"
              :functionOnClick="() => handleDeleteLink(currentLink.id)"
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
