<script setup lang="ts">
import {
  VButton,
  VDatepicker,
  VInput,
  VModal,
  VSearch,
  VSelect,
  VSpeakerAvatar,
  VTable,
  type VTableColumn,
  VTextarea,
  VToggleTwoOptions
} from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import anonymousUserImage from '@/assets/images/anonymous-user.png'
import CloudImage from '@/components/utils/CloudImage.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { getFilteredSpeakers } from '@/composables/speakers/useSpeakersFilter'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAgendaItemsStore } from '@/stores/agendaItems.store'
import { useEventsStore } from '@/stores/events.store'
import { useSpeakersStore } from '@/stores/speakers.store'
import type { components } from '@/types/swagger'
import { arraySortedByStringProperty } from '@/utils/arraySortedByStringProperty'
import { getFormattedDate } from '@/utils/getFormattedDate'
import { orderedMultilanguageTableTitles } from '@/utils/orderedMultilanguageTableTitles'
import { useDarkMode } from '@/utils/useDarkMode'

/**
 * Dark mode
 */
const { isDarkMode } = useDarkMode()

/**
 * Router
 */
const route = useRoute()

/****************************************
* NOTIFICATIONS
*****************************************/
const toast = useToast()

/****************************************
* TRANSLATIONS
*****************************************/
const { t, locale } = useI18n()

/****************************************
* TYPES
*****************************************/
type Speaker = components['schemas']['Speaker']
type AgendaItem = components['schemas']['AgendaItem']

/****************************************
* STORES
*****************************************/
const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent } = eventStore
const speakerStore = useSpeakersStore()
const { fetchAllSpeakers } = speakerStore
const agendaItemsStore = useAgendaItemsStore()
const {
  createAgendaItem,
  fetchAgendaItemById,
  updateAgendaItem,
  deleteAgendaItem,
  resetCurrentAgendaItem,
  sortAgendaItems
} = agendaItemsStore
const { currentAgendaItem, currentAgendaItemLanguage } = storeToRefs(agendaItemsStore)

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await fetchAllSpeakers()
  await fetchEventById(+eventId)
})

onUnmounted(() => {
  resetCurrentEvent()
  resetCurrentAgendaItem()
})

/****************************************
* REFS
*****************************************/
const eventId = route.params.id as string
const searchInput = ref<string>('')
const tempSelectedSpeakers = ref<Speaker[]>([])
const selectedSpeakers = ref<Speaker[]>([])
const isEditAgendaItemModalOpen = ref<boolean>(false)
const isDeleteAgendaItemModalOpen = ref<boolean>(false)

/****************************************
* COMPUTED VARIABLES
*****************************************/
const columns: ComputedRef<VTableColumn<string>[]> = computed(() => [
  {
    title: t('global.date'),
    name: 'startDate',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['startDate', 'endDate'],
    keyPositionInSmallDevices: 1
  },
  {
    title: t('global.title'),
    name: 'title',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['title'],
    keyPositionInSmallDevices: 3
  },
  {
    title: t('global.speakers'),
    name: 'speakers',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['speakers'],
    keyPositionInSmallDevices: 4
  },
  {
    title: t('global.type'),
    name: 'type',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['type']
  },
  {
    title: t('global.actions'),
    name: 'id',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 2
  }
])

const agendaItemOptions: any = computed(() => [
  {
    label: t('views.events.agenda.presentation'),
    value: 'presentation'
  },
  {
    label: t('views.events.agenda.pause'),
    value: 'pause'
  }
])

// Variable with the filtered speakers based on the search input
const speakersFiltered = computed(() => {
  return getFilteredSpeakers(currentEvent.value.speakers, searchInput.value)
})

/****************************************
* METHODS
*****************************************/
/**
 * Updates the search input value.
 * @param input - The search query.
 */
function filterSpeakers(input: string) {
  searchInput.value = input
}

/**
 * Adds or removes a speaker from the temporary selection list.
 * @param speaker - The speaker to add or remove.
 * @param isChecked - Whether the checkbox is checked or not.
 */
function addSpeakerToTempSelectedSpeakers(speaker: Speaker, isChecked: boolean) {
  if (isChecked) {
    const index = tempSelectedSpeakers.value.findIndex(
      (selectedSpeaker) => String(selectedSpeaker.id) === String(speaker.id)
    )
    if (index === -1) {
      tempSelectedSpeakers.value.push(speaker)
    }
  } else {
    // Remove the speaker from the temporary list if it is unchecked
    const index = tempSelectedSpeakers.value.findIndex(
      (selectedSpeaker) => String(selectedSpeaker.id) === String(speaker.id)
    )
    if (index !== -1) {
      tempSelectedSpeakers.value.splice(index, 1)
    }
  }
}

/**
 * Opens the modal to create a new agenda item.
 */
function createNewAgendaItem() {
  resetCurrentAgendaItem()
  // Open the edit agenda item modal
  isEditAgendaItemModalOpen.value = true
}

/**
 * Sets the selected option for the agenda item.
 * @param option - The selected option.
 */
function setOption(option: Record<string, any>) {
  if (currentAgendaItem.value) {
    currentAgendaItem.value.type = option.value
  }
}

/**
 * Opens the modal for a specific agenda item based on type (edit or delete).
 * @param agendaItemId - The ID of the agenda item.
 * @param type - The type of action (edit or delete).
 */
async function openAgendaItemModal(agendaItemId: number, type: 'edit' | 'delete') {
  // Find the agenda item by ID in the current event's agenda items
  const agendaItem = await fetchAgendaItemById(agendaItemId)

  if (agendaItem) {
    tempSelectedSpeakers.value = agendaItem.speakers || []
  }

  if (type === 'edit') {
    isEditAgendaItemModalOpen.value = true
  } else {
    isDeleteAgendaItemModalOpen.value = true
  }
}

/**
 * Closes the agenda item modal and resets the temporary and selected speakers.
 * @param type - The type of modal (edit or delete).
 */
function closeAgendaItemModal(type: 'edit' | 'delete') {
  if (type === 'edit') {
    isEditAgendaItemModalOpen.value = false
  } else {
    isDeleteAgendaItemModalOpen.value = false
  }

  // Clear temporary and selected speakers after closing the modal
  resetCurrentAgendaItem()

  tempSelectedSpeakers.value = []
  selectedSpeakers.value = []
}

/**
 * Updates the list of selected speakers with the temporary selection.
 */
function updateListOfSelectedSpeakers() {
  // merge the temporary list into the final one before sending it to the backend
  selectedSpeakers.value = [...selectedSpeakers.value, ...tempSelectedSpeakers.value]
}

/**
 * Handles the creation of a new agenda item.
 * @param agendaItem - The agenda item to create.
 */
async function handleCreateAgendaItems(agendaItem: AgendaItem) {
  updateListOfSelectedSpeakers()

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await createAgendaItem(agendaItem, currentEvent.value.id!, selectedSpeakers.value)

  await fetchEventById(currentEvent.value.id)

  sortAgendaItems(currentEvent.value.agendaItems!)

  closeAgendaItemModal('edit')

  toast.success(t('views.events.agenda.agendaItemCreated'))
}

/**
 * Handles the update of an existing agenda item.
 * @param agendaItem - The agenda item to update.
 */
async function handleUpdateAgendaItems(agendaItem: AgendaItem) {
  updateListOfSelectedSpeakers()

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await updateAgendaItem(agendaItem, selectedSpeakers.value)

  await fetchEventById(currentEvent.value.id)

  sortAgendaItems(currentEvent.value.agendaItems!)

  closeAgendaItemModal('edit')

  toast.success(t('views.events.agenda.agendaItemUpdated'))
}

/**
 * Handles the removal of an agenda item.
 * @param agendaItemId - The ID of the agenda item to remove.
 */
async function handleRemoveAgendaItem(agendaItemId: number) {
  if (!currentEvent.value) {
    throw new Error('Token or current event is missing')
  }

  // Remove the agenda item from the database
  await deleteAgendaItem(agendaItemId)

  // Remove the agenda item from the current event
  currentEvent.value.agendaItems = currentEvent.value.agendaItems?.filter(
    (item) => String(item.id) !== String(agendaItemId)
  )

  sortAgendaItems(currentEvent.value.agendaItems!)

  resetCurrentAgendaItem()

  closeAgendaItemModal('delete')

  toast.success(t('views.events.agenda.agendaItemDeleted'))
}

/**
 * Handles language changes for agenda items.
 * @param value - The new language value.
 */
function handleAgendaItemLanguageChange(value: string) {
  currentAgendaItemLanguage.value = value as 'de' | 'en'
}

/****************************************
* WATCHERS
*****************************************/
// Watcher to check if the start date is greater than the end date and reset the end date if it is
watch(
  () => currentAgendaItem.value.startDate,
  (newStartDate) => {
    if (new Date(newStartDate) > new Date(currentAgendaItem.value.endDate)) {
      currentAgendaItem.value.endDate = ''
    }
  },
  { deep: true, immediate: true }
)
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.agenda') }}
        </h1>
      </div>
      <VTable
        v-if="currentEvent.agendaItems.length > 0"
        :columns="columns"
        :data="currentEvent.agendaItems"
      >
        <template #startDate="{ row, col }">
          <div v-if="col.keys" class="flex flex-row flex-wrap">
            <span class="text-lg font-normal">
              {{ getFormattedDate(locale, row[col.keys[0]], true) }}
            </span>
            <span>&nbsp;-&nbsp;</span>
            <span class="text-lg font-normal">
              {{ getFormattedDate(locale, row[col.keys[1]], true) }}
            </span>
          </div>
        </template>
        <template #title="{ value }">
          <div
            v-for="({ str }, index) in orderedMultilanguageTableTitles(
              value,
              currentEvent.languages,
              locale
            )"
          >
            <span
              v-if="str !== undefined && str !== '' && str !== null"
              :key="str"
              class="font-semibold"
              :class="{
                'text-lg text-dark-grey dark:text-white': index === 0,
                'text-md text-misty-grey/60 dark:text-light-grey/50': index === 1
              }"
            >
              {{ str }}
            </span>
          </div>
        </template>
        <template #speakers="{ value }">
          <div v-if="value && value.length > 0">
            <span
              v-for="(speaker, index) in value"
              :key="`${speaker.firstName} ${speaker.lastName}`"
              class="text-lg font-normal"
            >
              {{ speaker.firstName }} {{ speaker.lastName
              }}<span v-if="index < value.length - 1"> | </span><span v-else></span>
            </span>
          </div>
          <span v-else></span>
        </template>
        <template #type="{ value }">
          <span class="text-lg font-normal">
            {{ value }}
          </span>
        </template>
        <template #id="{ value }">
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            class="cursor-pointer"
            :functionOnClick="() => openAgendaItemModal(Number(value), 'edit')"
          >
            <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
          </VButton>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            class="cursor-pointer"
            :functionOnClick="() => openAgendaItemModal(Number(value), 'delete')"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
      <div
        v-else
        class="h-[calc(100svh-18.5rem)] overflow-y-auto rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
      >
        <div>
          <p class="mb-4">
            {{ t('views.events.agenda.currentlyNoAgendaItems') }}
          </p>
        </div>
      </div>
      <div class="align-center my-5 flex w-full justify-end">
        <VButton :function-on-click="createNewAgendaItem" class="mb-5">
          {{ t('views.events.agenda.addNewAgendaItem') }}
        </VButton>
      </div>
    </section>
    <template #modal>
      <!-- Edit and delete agenda item modals -->
      <!-- the prop 'includeForm' adds a form inside the modal that wraps all slots and give the form a class 'group/form' that triggers the form validation classes of the submit button -->
      <VModal
        v-model:trigger="isEditAgendaItemModalOpen"
        @update:trigger="isEditAgendaItemModalOpen = $event"
        includeForm
        avoidCloseModalOnOverlay
        :function-on-close="() => closeAgendaItemModal('edit')"
      >
        <template #modalHeader>
          <div class="flex flex-col text-center uppercase">
            <p v-if="currentAgendaItem.title.de" class="text-lg text-dark-grey dark:text-white">
              {{ currentAgendaItem.title.de }}
            </p>
            <p
              v-if="currentAgendaItem.title.en"
              class="text-md text-misty-grey/60 dark:text-light-grey/50"
            >
              {{ currentAgendaItem.title.en }}
            </p>
          </div>
        </template>
        <template #modalBody>
          <VToggleTwoOptions
            v-if="currentEvent.isMultilanguage"
            v-model="currentAgendaItemLanguage"
            input-id="currentAgendaItemLanguage"
            leftOptionValue="de"
            rightOptionValue="en"
            @change="handleAgendaItemLanguageChange"
            class="mb-4"
          />
          <div class="mb-4 grid grid-cols-2 gap-8 lg:gap-16">
            <VDatepicker
              :input-id="`event-start-date-${currentAgendaItem.id}`"
              v-model="currentAgendaItem.startDate"
              :label="t('global.startDate')"
              :disabled="false"
              :locale="locale"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              class="col-span-2 lg:col-span-1"
              :min-date="currentEvent.startDate"
              :max-date="currentEvent.endDate"
              :select-text="t('global.select')"
              :cancel-text="t('global.cancel')"
              :now-button-label="t('global.now')"
              :dark="isDarkMode"
            />
            <VDatepicker
              :input-id="`event-end-date-${currentAgendaItem.id}`"
              v-model="currentAgendaItem.endDate"
              :label="t('global.endDate')"
              :disabled="false"
              :locale="locale"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              class="col-span-2 lg:col-span-1"
              :min-date="currentAgendaItem.startDate"
              :max-date="currentEvent.endDate"
              :select-text="t('global.select')"
              :cancel-text="t('global.cancel')"
              :now-button-label="t('global.now')"
              :dark="isDarkMode"
            />
          </div>
          <VInput
            v-model="currentAgendaItem.title[currentAgendaItemLanguage]"
            type="text"
            input-id="agenda-title"
            :label="t('global.title')"
            class="mb-4 w-full"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
          />
          <VTextarea
            v-model="currentAgendaItem.description[currentAgendaItemLanguage]"
            input-id="agenda-description"
            :label="t('global.description')"
            :placeholder="t('global.description')"
            :disabled="false"
            class="mb-4 w-full"
          />
          <VSelect
            v-model="currentAgendaItem.type"
            :selected-value="currentAgendaItem.type"
            :options="agendaItemOptions"
            :label="t('global.type')"
            :placeholder="t('global.type')"
            @selectOption="setOption"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            class="mb-4 w-full"
          />
          <div class="mb-4 flex w-full flex-col gap-2">
            <label class="text-base font-bold text-dark-grey dark:text-light-grey">
              {{ t('global.speakers') }}
            </label>
            <div v-if="speakersFiltered && speakersFiltered.length > 0" class="flex flex-col gap-4">
              <VSearch
                v-model="searchInput"
                :input-id="`agenda-speaker-search-${currentAgendaItem?.id}`"
                :placeholder="t('views.speakers.index.searchSpeakers')"
                :button-label="'Search'"
                @input="filterSpeakers(searchInput)"
                class="!gap-0"
              />
              <ul
                v-if="speakersFiltered.length > 0"
                class="flex max-h-80 flex-col gap-4 overflow-auto"
              >
                <li
                  v-for="speaker in arraySortedByStringProperty(speakersFiltered, 'lastName')"
                  :key="`notSpeaker-${speaker.firstName}-${speaker.lastName}`"
                >
                  <VSpeakerAvatar
                    :type="'checkbox'"
                    :input-id="`global-${speaker.id}`"
                    :title="`${speaker.firstName} ${speaker.lastName}`"
                    :subtitle="speaker.company"
                    :model-value="
                      tempSelectedSpeakers.some(
                        (tempSpeaker) => String(tempSpeaker.id) === String(speaker.id)
                      )
                    "
                    @change="addSpeakerToTempSelectedSpeakers(speaker, $event.target.checked)"
                    class="speaker-avatar"
                  >
                    <template #image>
                      <CloudImage
                        v-if="speaker.images?.length"
                        :imageName="speaker.images?.[0]?.['public_id']"
                        class="h-full w-full object-cover"
                        :alt="`${speaker.firstName} ${speaker.lastName}`"
                      />
                      <img
                        v-else
                        :src="anonymousUserImage"
                        :alt="`${speaker.firstName} ${speaker.lastName}`"
                        class="h-full w-full object-cover"
                      />
                    </template>
                  </VSpeakerAvatar>
                </li>
              </ul>
            </div>
            <div v-else>
              <p class="mb-4">{{ t('views.speakers.index.noSpeakersFound') }}</p>
              <I18nRouterLink :to="`/events/${currentEvent.id}/speakers`">
                <VButton
                  type="button"
                  appearance="default"
                  :label="t('views.speakers.index.goToSpeakersManagement')"
                  size="medium"
                >
                  <FontAwesomeIcon :icon="['fal', 'headset']" />
                </VButton>
              </I18nRouterLink>
            </div>
          </div>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="() => closeAgendaItemModal('edit')"
            />
            <VButton
              v-if="currentAgendaItem.id"
              type="submit"
              appearance="default"
              :label="t('global.update')"
              size="medium"
              :functionOnClick="
                () => {
                  handleUpdateAgendaItems(currentAgendaItem as AgendaItem)
                }
              "
            />
            <VButton
              v-else
              type="submit"
              appearance="default"
              :label="t('global.save')"
              size="medium"
              :functionOnClick="
                () => {
                  handleCreateAgendaItems(currentAgendaItem as AgendaItem)
                }
              "
            />
          </div>
        </template>
      </VModal>
      <VModal
        v-model:trigger="isDeleteAgendaItemModalOpen"
        @update:trigger="isDeleteAgendaItemModalOpen = $event"
        :function-on-close="() => closeAgendaItemModal('delete')"
      >
        <template #modalHeader>
          <div class="flex flex-col text-center uppercase">
            <p v-if="currentAgendaItem.title.de" class="text-lg text-dark-grey dark:text-white">
              {{ currentAgendaItem.title.de }}
            </p>
            <p
              v-if="currentAgendaItem.title.en"
              class="text-md text-misty-grey/60 dark:text-light-grey/50"
            >
              {{ currentAgendaItem.title.en }}
            </p>
          </div>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.agenda.confirmDeleteAgendaItem') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="() => closeAgendaItemModal('delete')"
            />
            <VButton
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleRemoveAgendaItem(currentAgendaItem!.id)
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
